/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Dialog, DialogContent, DialogContentText,
  makeStyles, CircularProgress,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  customAlert: {
    padding: '1.5em',
  },
  alertHeader: {
    padding: 30,
  },
  alertProgress: {
    textAlign: 'center', marginBottom: 30,
  },
  alertText: {
    font: theme.typography.h3.font,
    fontSize: 16,
    color: theme.palette.common.black,
    paddingBottom: 8,
  },
}));

export default function ProgressDialog({
  open, setOpen, header,
}) {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description" className={classes.alertText}>
          {header || 'Alert'}
        </DialogContentText>
        <DialogContentText className={classes.alertProgress}>
          <CircularProgress size={60} />
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
ProgressDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func,
  header: PropTypes.string.isRequired,
};

ProgressDialog.defaultProps = {
  setOpen: () => { },
};
