/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from 'react';
import {
  AppBar, Hidden, IconButton, Toolbar, Grid, Typography, Badge, Menu, List, ListItem, ListItemText, ListItemSecondaryAction,
  makeStyles, Link, Box, Tooltip,
} from '@material-ui/core';
import { Auth, API } from 'aws-amplify';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import MenuIcon from '@material-ui/icons/Menu';
import InfoIcon from '@material-ui/icons/Info';
import NotificationsIcon from '@material-ui/icons/Notifications';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';
import { NEW_DEPLOYMENT_PATH } from '../utils/constants';
import ProjectPicker from './utils/ProjectPicker';
import Profile from './Profile';
import GraphqlService from '../service/graphqlService';
import LoadingCard from './utils/LoadingCard';
import { useSnackbar } from '../providers/SnackbarContext';
import { onNewNotificationItem } from '../config/graphql';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.appBar,
    backgroundColor: theme.palette.background.header,
    color: theme.palette.common.black,
  },
  grow: {
    flexGrow: 1,
  },
  navDisplayFlex: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  linkText: {
    textDecoration: 'none',
    textTransform: 'uppercase',
    color: theme.palette.common.black,
  },
  toolbarOptions: {
    marginRight: 'auto',
    direction: 'ltr',
    width: '100%',
  },
  gridProfile: {
    alignSelf: 'center',
    justifyContent: 'flex-end',
    width: 'auto',
  },
  projectPickerGrid: {
    flexGrow: 1,
    paddingTop: 12,
    paddingBottom: 12,
  },
  notificationButton: {
    marginLeft: 'auto',
  },
  notificationMenu: {
    width: '500px',
  },
  notificationMenuPaper: {
    width: '500px',
    maxHeight: '500px',
    overflow: 'auto',
    backgroundColor: theme.palette.primary.main,
  },
  notificationTitle: {
    paddingLeft: 16,
  },
  listItemRead: {
    backgroundColor: '#f0f0f0', // Light gray background for read notifications
  },
  listItemUnread: {
    // blue background for unread notifications
    backgroundColor: '#e3f2fd',
  },
  noPadding: {
    padding: 12,
  },
  listNoPadding: {
    paddingTop: 0,
    paddingBottom: 0,
    height: '100%',
  },
  notificationsHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  closeNotificationsButton: {
    color: theme.palette.common.white,
  },
  closeNotificationButton: {
    paddingRight: 16,
  },
  noNotifications: {
    textAlign: 'center',
    color: theme.palette.common.white,
  },
  wrapIcon: {
    verticalAlign: 'middle',
    display: 'inline-flex',
  },
  textWrap: {
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    hyphens: 'auto',
    width: '85%',
  },
  dateNotificationText: {
    fontSize: 10,
    color: theme.palette.text.secondary,
  },
  profileCursor: {
    cursor: 'pointer',
  },
}));

function Header({ handleDrawer, handleOpenChangeLogModal }) {
  const classes = useStyles();
  const { pathname } = useLocation();
  const [username, setUsername] = useState('Welcome');
  const [userToken, setUserToken] = useState(undefined);
  const [showing, setShowing] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const queryClient = useQueryClient();

  const openSnackBar = useSnackbar();

  useEffect(() => {
    async function checkUser() {
      const authUser = await Auth.currentAuthenticatedUser();
      setUsername((prev) => authUser?.signInUserSession?.idToken?.payload?.identities?.shift().userId
        || authUser?.attributes?.email
        || prev);
      setUserToken(authUser.signInUserSession.idToken.jwtToken);
    }

    checkUser();
  }, []);

  useEffect(() => {
    if (!userToken || !username) {
      return;
    }

    const subscribeToNotifications = async () => {
      try {
        if (!userToken) {
          return null;
        }
        const subscription = API.graphql({
          query: onNewNotificationItem,
          authMode: 'AMAZON_COGNITO_USER_POOLS',
          variables: {
            authToken: Auth.user.attributes?.sub || Auth.user.signInUserSession?.accessToken?.payload?.sub,
          },
        }).subscribe({
          next: (response) => {
            if (response.value.data.onNewNotificationItem.notification.user !== username) {
              return;
            }
            queryClient.invalidateQueries(['notifications']);
            queryClient.invalidateQueries(['unreadNotificationsCount']);
            openSnackBar('New notification received', 'info');
          },
          error: (error) => {
            openSnackBar(`Error subscribing to notifications: ${error}`, 'error');
          },
        });
        // Cleanup function to unsubscribe
        return () => subscription.unsubscribe();
      } catch (error) {
        console.log(`Error setting up subscription: ${error}`, 'error');
        return null;
      }
    };

    subscribeToNotifications();
  }, [userToken, username]);

  const getNotifications = async () => {
    if (!username) {
      return [];
    }
    const result = await GraphqlService.getNotifications({
      user: username,
    });

    return result || [];
  };

  const {
    data: notificationsData,
    isLoading: notificationsLoading,
  } = useQuery(['notifications', username], getNotifications, {
    cacheTime: 0,
    enabled: !!username,
    // sort by timestamp in descending order
    select: (data) => data?.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)),
  });

  const getUnreadNotificationsCount = async () => GraphqlService.getUnreadNotificationsCount({
    user: username,
  });

  // Use react-query to fetch data
  const {
    data: unreadCountData,
  } = useQuery(['unreadNotificationsCount', username], getUnreadNotificationsCount, {
    cacheTime: 0,
    enabled: !!username, // Only run query if username is available
  });

  const markAsReadMutation = useMutation(
    (variables) => GraphqlService.markAsReadMutation(variables.notificationId),
    {
      onSuccess: () => {
        openSnackBar('Notification read', 'success');
        queryClient.invalidateQueries(['notifications']);
        queryClient.invalidateQueries(['unreadNotificationsCount']);
      },
    },
  );

  const handleMarkAsRead = (notificationId) => {
    markAsReadMutation.mutate({ notificationId });
  };

  const deleteNotificationMutation = useMutation(
    (variables) => GraphqlService.deleteNotification(variables.notificationId),
    {
      onSuccess: () => {
        openSnackBar('Notification deleted', 'success');
        queryClient.invalidateQueries(['notifications']);
        queryClient.invalidateQueries(['unreadNotificationsCount']);
      },
    },
  );

  const handleDeleteNotification = (notificationId) => {
    deleteNotificationMutation.mutate({ notificationId });
  };

  function handleMenu(event) {
    setAnchorEl(event.currentTarget);
    // when the menu is opened, mark all notifications as read
    notificationsData?.forEach((notification) => {
      if (!notification.read) {
        handleMarkAsRead(notification.id);
      }
    });
  }

  function handleClose() {
    setAnchorEl(null);
  }

  useEffect(() => {
    function locationWithoutModels() {
      setShowing(!pathname.includes(NEW_DEPLOYMENT_PATH));
    }
    locationWithoutModels();
  }, [pathname]);

  const closeNotificationMenu = () => {
    setAnchorEl(null);
  };

  const detectAndRenderLinks = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(urlRegex);

    return parts.map((part) => {
      if (part.match(urlRegex)) {
        return (
          <Link href={part} key={uuidv4()} target="_blank" rel="noopener noreferrer">
            here
          </Link>

        );
      }
      return part;
    });
  };

  const setTitle = (title, description, timestamp, type) => {
    if (type === 'info') {
      // return the info with the title
      return (
        <Grid container display="flex" direction="column">
          <Grid container display="flex" spacing={1}>
            <Grid item>
              <InfoIcon />
            </Grid>
            <Grid item className={classes.textWrap}>
              <Typography>
                {title}
              </Typography>
              <Typography variant="body3" className={classes.dateNotificationText}>
                {new Date(timestamp).toLocaleString()}
              </Typography>
            </Grid>

          </Grid>
          <Grid item className={classes.textWrap}>
            <Typography variant="body2">
              {detectAndRenderLinks(description)}
            </Typography>
          </Grid>

        </Grid>
      );
    }
    if (type === 'warning') {
      return <WarningIcon />;
    }
    if (type === 'error') {
      return <ErrorIcon />;
    }
    return (
      <Grid>
        <InfoIcon />
        <ListItemText
          primary={title}
          secondary={description}
        />
      </Grid>
    );
  };

  const notificationsList = (item) => {
    if (notificationsLoading) {
      return (
        <ListItem className={classes.noPadding}>
          <LoadingCard />
        </ListItem>
      );
    }

    if (!item || item.length === 0) {
      return (
        <ListItem className={classes.noPadding}>
          <ListItemText primary="No notifications" className={classes.noNotifications} />
        </ListItem>
      );
    }

    return item?.map((notification) => (
      <ListItem
        key={notification.id}
        className={`${classes.noPadding} ${notification.read ? classes.listItemRead : classes.listItemUnread}`}
      >
        {setTitle(notification.title, notification.description, notification.timestamp, notification.type)}
        <ListItemSecondaryAction>
          {/* If the notification is already read, show a check mark */}
          {!notification.read && (
            <IconButton
              edge="end"
              aria-label="Mark as read"
              onClick={() => handleMarkAsRead(notification.id)}
            >
              <DoneIcon />
            </IconButton>
          )}
          <IconButton
            edge="end"
            aria-label="Delete"
            onClick={() => handleDeleteNotification(notification.id)}
          >
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    ));
  };

  return (
    <>
      <AppBar position="static" className={classes.appBar} elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" justify="space-between">
            <Grid item>
              <Grid container alignItems="center">
                <Hidden mdUp implementation="css">
                  <IconButton
                    color="inherit"
                    aria-label="Open drawer"
                    edge="start"
                    onClick={handleDrawer}
                  >
                    <MenuIcon />
                  </IconButton>
                </Hidden>
                <div className={classes.toolbarOptions}>
                  <ProjectPicker disabled={!showing} />
                </div>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container alignItems="center" spacing={2}>
                <Box mr={2}>
                  <Tooltip title="Notifications">
                    <IconButton
                      color="inherit"
                      aria-label="Open notifications"
                      edge="end"
                      onClick={handleMenu}
                      className={classes.notificationButton}
                    >
                      <Badge badgeContent={unreadCountData || 0} color="primary">
                        <NotificationsIcon />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                </Box>
                <Menu
                  id="notification-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  classes={{ paper: classes.notificationMenuPaper }}
                >
                  <Grid container display="flex" className={classes.notificationsHeader} justifyContent="space-between">
                    <Grid item alignContent="center" className={classes.notificationTitle}>
                      <ListItemText
                        primary={`Notifications (${unreadCountData || 0} unread)`}
                      />
                    </Grid>
                    <Grid item alignContent="center" className={classes.closeNotificationButton}>
                      <IconButton
                        edge="end"
                        aria-label="Close notifications"
                        onClick={closeNotificationMenu}
                        className={classes.closeNotificationsButton}
                      >
                        <HighlightOffIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <List className={classes.listNoPadding}>
                    {notificationsList(notificationsData)}
                  </List>

                </Menu>
                <Box mr={2}>
                  <Tooltip title="Information">
                    <IconButton
                      color="inherit"
                      aria-label="Open change modal"
                      edge="start"
                      onClick={handleOpenChangeLogModal}
                    >
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Tooltip title="Profile">
                  <Box className={classes.profileCursor}>
                    <Profile user={username} />
                  </Box>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
}

Header.propTypes = {
  handleDrawer: PropTypes.func.isRequired,
  handleOpenChangeLogModal: PropTypes.func.isRequired,
};

export default Header;
