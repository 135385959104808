/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Tooltip,
  IconButton,
  FormControlLabel,
  makeStyles, Typography, useTheme,
  ListItem, ListItemText, TableContainer, Table, TableBody, TableRow, TableCell, CircularProgress,
  TextField, TablePagination,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import {
  React, useEffect, useState, useMemo,
} from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import InfoIcon from '@material-ui/icons/Info';
import { Doughnut } from 'react-chartjs-2';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { TableHead } from '@aws-amplify/ui-react';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import SortIcon from '@material-ui/icons/Sort';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { v4 as uuidv4 } from 'uuid';
import GraphqlService from '../../service/graphqlService';
import { ReactComponent as DeleteIcon } from '../../assets/ic_bin.svg';
import { ReactComponent as GlobalDeploymentIcon } from '../../assets/ic_global_deployment.svg';
import { ReactComponent as RegexIcon } from '../../assets/ic_regex.svg';
import { ReactComponent as MarketIcon } from '../../assets/ic_market.svg';
import {
  formatToPrintableDate,
} from '../../utils/dateUtils';
import { downloadFile } from '../../utils/exportUtils';
import {
  CSV_FILETYPE, DELETE_DEPLOY_PERMISSION, TOOLTIP_GLOBAL_DEPLOYMENT, TOOLTIP_REGEX_DEPLOYMENT,
  TOOLTIP_MARKET_DEPLOYMENT, TOOLTIP_DEVICE_DEPLOYMENT, TOOLTIP_DEVICE_DEPLOYMENT_CSV,
} from '../../utils/constants';
import Restricted from '../../authorization/Restricted';
import KebabMenu from '../utils/KebabMenu';
import { useSnackbar } from '../../providers/SnackbarContext';
import { useProject } from '../../config/ProjectContext';

export default function DeploymentAccordion({
  deployment, id, deleted, deleteDeploy,
}) {
  const useStyles = makeStyles((theme) => ({
    rootDetail: {
      flexGrow: 1,
      background: '#E2E2E2',
      height: '100%',
    },
    statusBullet: {
      display: 'inline-block',
      width: '10px',
      height: '10px',
      borderRadius: '50%',
      marginRight: '5px',
    },
    detailItem: {
      borderRadius: '8px 0px 0px 8px',
      background: 'white',
    },
    detailInfoItem: {
      padding: 10,
    },
    detailInsight: {
      background: 'white',
      borderRadius: '0px 8px 8px 0px',
      padding: 8,
    },
    infoSection: {
      textAlign: 'left',
      height: '100%',
      background: 'white',
      borderRadius: 8,
      padding: 12,
    },
    headerDetailTitle: {
      paddingBottom: theme.spacing(2),
      fontWeight: 'bold',
    },
    infoValue: {
      marginBottom: theme.spacing(1),
    },
    headerValue: {
      fontWeight: 'bold',
    },

    details: {
      padding: 0,
    },
    cardTitle: {
      fontSize: 16,
      whiteSpace: 'normal',
      wordBreak: 'break-word',
    },
    cardContent: {
      backgroundColor: 'white',
      color: theme.palette.primary.text,
      flexGrow: 1,
    },
    detailsTitle: {
      fontSize: 14,
      paddingRight: '8px',
    },
    accordionContainer: {
      marginLeft: 24,
      marginRight: 24,
      borderRadius: '0px',
    },
    root: {
      padding: 0,
      backgroundColor: 'white',
      marginBottom: 4,
      marginTop: 4,
      borderRadius: '0px',
    },
    summaryRoot: {
      backgroundColor: 'white',
      borderRadius: '0px',
      margin: 0,
    },
    accordionContent: {
      margin: -12,
    },
    headingTitle: {
      fontSize: 16,
      fontWeight: theme.typography.fontWeightMedium,
    },
    mainDetailTitle: {
      padding: theme.spacing(2),
      fontWeight: 'bold',
    },
    headingTitleElements: {
      fontWeight: theme.typography.h6.fontWeight,
      fontFamily: theme.typography.h5.fontFamily,
      lineHeight: theme.typography.h5.lineHeight,
      letterSpacing: theme.typography.h5.letterSpacing,
      fontSize: theme.typography.h5.fontSize,
      color: theme.palette.primary.text,
    },
    headingDescription: {
      fontSize: 14,
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.secondary.text,
    },
    headingDate: {
      fontSize: 14,
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.primary.text,
    },
    headingDeletedDate: {
      fontSize: 14,
      fontWeight: theme.typography.fontWeightRegular,
      color: 'red',
    },
    detailsText: {
      fontSize: 14,
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.secondary.text,
    },
    iconsBox: {
      float: 'center',
      margin: 0,
    },
    icon: {
      color: theme.palette.primary.text,
    },
    block: {
      background: theme.palette.background.typography,

    },
    blockTitle: {
      fontSize: 16,
      fontWeight: 500,

    },
    key: {
      color: theme.palette.primary.subMain,
      whiteSpace: 'nowrap',
    },
    cardHeader: {
      padding: 10,
    },
    tableBodyDetailItem: {
      height: '100%',
      background: 'white',
    },
    tableHeadDetailItem: {
      background: '#F2F2F2',
    },
    tableHead: {
      fontWeight: 'bold',
    },
    bodyValue: {
      whiteSpace: 'normal',
      wordBreak: 'break-word',
    },
    overallContainer: {
      background: '#E2E2E2',
      padding: 10,
    },
    overallRootContainer: {
      marginBottom: 32,
    },
    infoItems: {
      marginBottom: 6,
    },
    deployHeaderItem: {
      marginBottom: 16,
    },
    tableStatus: {
      margin: 8,
      borderRadius: 8,
    },
    insightContainer: {
      width: '100%',
      height: '100%',
      maxWidth: 350,
      maxHeight: 350,
      alignContent: 'center',
      alignItems: 'center',
    },
    detailSerialFilterStyle: {
      width: '100%',
      minWidth: 250,

    },
    detailStatusFilterStyle: {
      width: '100%',
      minWidth: 190,
      marginTop: 6,
      paddingRight: 12,
    },
    dotsIcons: {
      alignSelf: 'center',
    },
    developmentTypeIcons: {
      alignSelf: 'flex-start',
    },
    downloadCloud: {
      padding: 0,

    },
    globalDeployIcon: {
      marginTop: 4,
    },
    gridChartProgress: {
      marginTop: 16,
      marginLeft: 16,
    },
  }));

  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('deviceSerial');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filter, setFilter] = useState('');

  const [deploymentList, setDeploymentList] = useState([]);
  const [overallStatus, setOverallStatus] = useState([]);
  const [detailStatus, setDetailStatus] = useState('NO-FILTER');

  const theme = useTheme();
  const { project } = useProject();

  const [expanded, setExpanded] = useState(false);

  const openSnackBar = useSnackbar();

  function printTarget(target) {
    try {
      if (target) {
        const targetStr = target.substring(1, target.length - 1).replace(/\s/g, '').replaceAll(',', ', ');
        return targetStr;
      }
      return 'Not available';
    } catch (e) {
      return '*';
    }
  }

  const DEPLOYMENT_STATUS = {
    0: 'Error',
    1: 'Request',
    2: 'Validation',
    3: 'Download',
    4: 'Asset Verification',
    5: 'Confirm',
    6: 'Execute',
    7: 'Done',
  };

  function printCountTarget() {
    try {
      if (deployment.target) {
        // device deployment
        const target = JSON.parse(deployment.target);
        if (Array.isArray(target)) {
          return target.length;
        }
      } else if (deployment.targetMarket) {
        // market deployment
        const resultPrintTarget = printTarget();
        return resultPrintTarget.split(',').length;
      }
      return 1;
    } catch (e) {
      return '*';
    }
  }

  function convertToCSV(rows) {
    let header = '';
    // eslint-disable-next-line no-return-assign
    Object.keys(rows[0]).map((pr) => (header += `${pr};`));

    let str = '';
    rows.forEach((row) => {
      str += `${row}\r\n`;
    });
    return `${str}`;
  }

  function exportTargetToCSV() {
    try {
      let target = JSON.parse(deployment.target);
      // for some projects the target field that arrives from AssetDeploy API is not an array
      // in these cases we will transform to an array
      if (typeof target === 'string') {
        target = [target];
      }

      if (Array.isArray(target)) {
        // Add header
        target.unshift('serials');

        const csv = convertToCSV(target);

        const exportedFilename = `Deployment${deployment.deploymentID}_deploymentSerials`;

        downloadFile(csv, exportedFilename, CSV_FILETYPE);
      } else {
        window.alert('Couldn\'t export serials', 'Could not parse serials into .csv');
      }
    } catch (e) {
      window.alert('Couldn\'t export serials', 'There was an error exporting the serials into .csv');
    }
  }

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      openSnackBar(`Value ${text} copied successfully to the clipboad!`, 'success');
    }).catch((err) => {
      openSnackBar('Failed to copy text to clipboard', err);
    });
  };

  const openAsset = (fileId) => {
    const url = `${window.location.origin}/#/assetDetail/${fileId}`;
    window.open(url, '_blank');
  };

  const statusColors = {
    Done: 'green',
    Error: 'red',
    Executing: 'orange',
  };

  async function searchDeploymentStatus({
    startDate, endDate, deploymentId, params,
  }) {
    const response = await GraphqlService.getLifeCycleObject(
      project.code, `machinecount/${project.code}/${deploymentId}`, JSON.stringify(params), '', startDate, endDate, 90, undefined,
    );
    // eslint-disable-next-line no-plusplus
    if (response) {
      const generalData = JSON.parse(response.data);
      return generalData;
    }
    return [];
  }

  // Define a query function
  const fetchSearchDeploymentStatus = async (params) => {
    if (!expanded) return null;

    return searchDeploymentStatus({
      startDate: moment().subtract(90, 'days').toISOString(),
      endDate: moment().toISOString(),
      deploymentId: deployment.deploymentID,
      params,
    });
  };

  // Use the useQuery hook
  const { data: noErrorStatus, error: errorFetchingNoErrorStatus, isFetching: isFetchingNoErrorStatus } = useQuery({
    queryKey: ['searchNoErrorDeploymentStatus', deployment.deploymentID, expanded],
    queryFn: () => fetchSearchDeploymentStatus({ isError: false }),
    enabled: expanded,
    select: (res) => {
      if (!res?.validData) return 0;
      const doneRes = res.validData.filter((item) => item.status === 7);
      const execRes = res.validData.filter((item) => item.status >= 1 && item.status < 7);
      return {
        done: doneRes?.reduce((acc, item) => (acc + (item?.machineCount || 0)), 0),
        executing: execRes?.reduce((acc, item) => (acc + (item?.machineCount || 0)), 0),
      };
    },
  });

  // Use the useQuery hook
  const { data: errorStatus, error: errorFetchingErrorStatus, isFetching: isFetchingErrorStatus } = useQuery({
    queryKey: ['searchErrorDeploymentStatus', deployment.deploymentID, expanded],
    queryFn: () => fetchSearchDeploymentStatus({ isError: true }),
    enabled: expanded,
    select: (res) => res.validData?.reduce((acc, item) => (acc + (item?.machineCount || 0)), 0),
  });

  async function searchDeploymentDetails({
    startDate, endDate, deploymentId, params,
  }) {
    const response = await GraphqlService.getLifeCycleObject(
      project.code, `machinelist/${project.code}/${deploymentId}`, JSON.stringify(params), '', startDate, endDate, 90, undefined,
    );

    if (response) {
      const generalData = JSON.parse(response.data);
      return generalData;
    }
    return [];
  }

  // Define a query function
  const fetchDeploymentDetails = async (params) => {
    if (!expanded) return null;

    return searchDeploymentDetails({
      startDate: moment().subtract(90, 'days').toISOString(),
      endDate: moment().toISOString(),
      deploymentId: deployment.deploymentID,
      params,
    });
  };

  const { data: noErrorDeploymentDetails, error: errorFetchingNoErrorDeploymentDetails, isFetching: isFetchingNoErrorDeploymentDetails } = useQuery({
    queryKey: ['searchNoErrorDeploymentDetails', deployment.deploymentID, expanded],
    queryFn: () => fetchDeploymentDetails({}),
    enabled: expanded,
    select: (res) => {
      if (!res?.validData) return [];
      return res.validData.map((item) => ({
        deviceSerial: item.deviceSerial,
        lastUpdateRequest: moment(item.updated_last_on).format('DD/MM/YYYY h:mm A'),
        status: DEPLOYMENT_STATUS[item.status],
      }));
    },
  });

  const { data: errorDeploymentDetails, error: errorFetchingErrorDeploymentDetails, isFetching: isFetchingErrorDeploymentDetails } = useQuery({
    queryKey: ['searchErrorDeploymentDetails', deployment.deploymentID, expanded],
    queryFn: () => fetchDeploymentDetails({ isError: true }),
    enabled: expanded,
    select: (res) => {
      if (!res?.validData) return [];
      return res.validData.map((item) => ({
        deviceSerial: item.deviceSerial,
        lastUpdateRequest: moment(item.updated_last_on).format('DD/MM/YYYY h:mm A'),
        status: DEPLOYMENT_STATUS[0],
      }));
    },
  });

  // this use effect joins the noError and error status, besides ordering by lastUpdateRequest
  useEffect(() => {
    if (!noErrorDeploymentDetails || !errorDeploymentDetails) return;
    const allData = [...noErrorDeploymentDetails, ...errorDeploymentDetails];
    allData.sort((a, b) => new Date(b.lastUpdateRequest) - new Date(a.lastUpdateRequest));
    setDeploymentList(allData);
  }, [noErrorDeploymentDetails, errorDeploymentDetails]);

  // this use effect sums the total number of errors, done and executing machines
  useEffect(() => {
    if (noErrorStatus === undefined || errorStatus === undefined) return;
    const totalDone = noErrorStatus.done + errorStatus + noErrorStatus.executing;
    const statusInfo = [
      {
        status: 'Done',
        count: noErrorStatus.done,
        percentage: noErrorStatus.done ? Math.round((noErrorStatus.done / totalDone) * 100) : 0,
      },
      {
        status: 'Error',
        count: errorStatus || 0,
        percentage: errorStatus ? Math.round((errorStatus / totalDone) * 100) : 0,
      },
      {
        status: 'Executing',
        count: noErrorStatus.executing,
        percentage: noErrorStatus.executing ? Math.round((noErrorStatus.executing / totalDone) * 100) : 0,
      },
      {
        status: 'Total',
        count: totalDone,
      },
    ];

    const chartData = {
      datasets: [
        {
          label: 'Deployment Status',
          data: [statusInfo[0].percentage, statusInfo[1].percentage, statusInfo[2].percentage],
          backgroundColor: ['green', 'red', 'orange'],
          hoverOffset: 3,
        },
      ],
    };

    setOverallStatus({
      statusInfo,
      chartData,
    });
  }, [noErrorStatus, errorStatus]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log('event.target.value:', event.target.value);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (event) => {
    setPage(0);
    setFilter(event.target.value);
  };

  const filteredData = useMemo(() => deploymentList.filter((row) => row.deviceSerial.toLowerCase().includes(filter.toLowerCase())
    || row.status.toLowerCase().includes(filter.toLowerCase())), [deploymentList, filter]);

  const sortedData = useMemo(() => filteredData.sort((a, b) => {
    if (orderBy === 'deviceSerial' || orderBy === 'status') {
      return (order === 'asc' ? a[orderBy].localeCompare(b[orderBy]) : b[orderBy].localeCompare(a[orderBy]));
    }

    return order === 'asc' ? moment(a[orderBy], 'DD/MM/YYYY hh:mm A') - moment(b[orderBy], 'DD/MM/YYYY hh:mm A') : moment(b[orderBy], 'DD/MM/YYYY hh:mm A') - moment(a[orderBy], 'DD/MM/YYYY hh:mm A');
  }).reduce((acc, row) => {
    // add key for table
    acc.push({ ...row, id: uuidv4() });
    return acc;
  }, []), [filteredData, order, orderBy]);

  const handleDetailStatusChange = (event) => {
    setDetailStatus(event.target.value);
    setFilter(event.target.value === 'NO-FILTER' ? '' : event.target.value);
  };

  const doughnutOptions = {
    plugins: {
      tooltip: {
        enabled: false,
      },
    },
    cutout: '70%',
    responsive: true,
  };

  const uniqueStatuses = Array.from(new Set(deploymentList.map((deployListItem) => deployListItem.status)));

  const hasInsight = JSON.stringify(overallStatus?.chartData?.datasets[0]?.data) !== JSON.stringify([0, 0, 0]);

  if (!deployment || !deployment.file || !statusColors) {
    return null;
  }

  return (
    <div className={classes.accordionContainer}>
      <Accordion className={classes.root} id={`deployment-accordion-detail-${id}`} expanded={expanded} TransitionProps={{ unmountOnExit: true }} onChange={() => setExpanded(!expanded)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon id={`${id}btnExpand`} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.summaryRoot}
          classes={{ content: classes.accordionContent }} // Apply the custom classes
        >
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            display="flex-start"
          >
            {/*  DOTS */}
            <Grid container xs={1} sm={1} className={classes.dotsIcons}>
              <Grid container display="flex" direction="row" justifyContent="center" alignSelf="center">

                <KebabMenu
                  deployment={deployment}
                  items={[{
                    optionName: 'Details',
                    optionURL: '/statistics/deploymentDetails',
                  },
                  {
                    optionName: 'Summary',
                    optionURL: '/statistics/deploymentStatus',
                  },
                  ]}
                />

              </Grid>

            </Grid>

            {/* Type */}
            <Grid container xs={4} sm={2}>
              <ListItem>
                <ListItemText
                  id={`${id}Type`}
                  primary={
                    <Typography variant="caption" color="textSecondary">Type</Typography>
                  }
                  secondary={<Typography variant="body2">{deployment.file.type}</Typography>}
                />
              </ListItem>
            </Grid>

            {/* Version */}
            <Grid container xs={4} sm={2}>
              <ListItem>
                <ListItemText
                  id={`${id}Version`}
                  primary={
                    <Typography variant="caption" color="textSecondary">Version</Typography>
                  }
                  secondary={<Typography variant="body2">{deployment.file.assetDescription ? `v.${JSON.parse(deployment.file.assetDescription)?.Version}` : 'Not available'}</Typography>}
                />
              </ListItem>
            </Grid>

            {/* dateTime */}
            <Grid container xs={4} sm={3}>
              <ListItem>
                <ListItemText
                  id={`${id}Date`}
                  primary={
                    <Typography variant="caption" color="textSecondary">Date</Typography>
                  }
                  secondary={<Typography variant="body2">{formatToPrintableDate(deployment.dateTime)}</Typography>}
                />
              </ListItem>
            </Grid>

            {/* DeletedDate */}
            {deleted && (
              <Grid item xs={4} sm={2}>

                <ListItem>
                  <ListItemText
                    id={`${id}DeletedDate`}
                    primary={
                      <Typography variant="caption" color="textSecondary">Deleted Date</Typography>
                    }
                    secondary={<Typography variant="body2" className={classes.headingDeletedDate}>{formatToPrintableDate(deployment.deleteTime)}</Typography>}
                  />
                </ListItem>

              </Grid>
            )}

            {/* <Grid container xs={4} sm={4} className={classes.headingDeletedDate}> */}
            {/* User Descripiton */}
            {/* {deployment.deployDescription?.userDescription && (
                <ListItem>
                  <ListItemText
                    className={classes.headingDescription}
                    id={`${id}UserDescription`}
                    primary={
                      <Typography variant="caption" color="textSecondary">User Description</Typography>
                    }
                    secondary={<Typography variant="body2">{`${deployment.deployDescription.userDescription}`}</Typography>}
                  />
                </ListItem> */}
            {/* )} */}
            {/* </Grid> */}

            {/* Number of machines */}
            {/* <Grid container xs={2} sm={1}>
              {deployment.target && deployment.target !== '"*"' && (

                <ListItem>
                  <ListItemText
                    id={`${id}Machines`}
                    primary={
                      <Typography variant="caption" color="textSecondary">Machines</Typography>
                    }
                    secondary={<Typography className={classes.headingTitle} id={`${id}NMachines`}>{printCountTarget()}</Typography>}
                  />

                </ListItem>

              )}
            </Grid> */}
            {/* Development Types */}
            <Grid container xs={8} sm={2} className={classes.developmentTypeIcons}>

              <ListItem>
                <ListItemText
                  id={`${id}Development Type`}
                  primary={
                    <Typography variant="caption" color="textSecondary">Deployment Type</Typography>
                  }
                  secondary={(
                    <Grid container display="flex" direction="row" alignItems="center" spacing={2}>
                      {!deployment.targetRegex && !deployment.targetMarket && deployment.target !== '"*"' && (
                        <Grid item>
                          <Typography className={classes.headingTitle} id={`${id}NMachines`}>{printCountTarget()}</Typography>
                        </Grid>
                      )}
                      {!deployment.targetRegex && !deployment.targetMarket && deployment.target !== '"*"' && (
                        <Grid item>

                          <Tooltip title={`${TOOLTIP_DEVICE_DEPLOYMENT}. ${TOOLTIP_DEVICE_DEPLOYMENT_CSV}`} aria-label="download">
                            <IconButton aria-label="download-button" id={`${id}btnDownloadNMachines`} className={classes.downloadCloud} onClick={() => exportTargetToCSV()}>
                              <CloudDownloadIcon className={classes.icon} />
                            </IconButton>
                          </Tooltip>

                        </Grid>
                      )}
                      {!deployment.targetRegex && deployment.target === '"*"' && (
                        <Grid item className={classes.globalDeployIcon}>

                          <Tooltip title={TOOLTIP_GLOBAL_DEPLOYMENT} aria-label="deployGlobal">
                            <Typography className={classes.headingTitle}>
                              {/* GlobalDeploymentIcon should be replaced with your actual global deployment icon component */}
                              <GlobalDeploymentIcon className={classes.icon} />
                            </Typography>
                          </Tooltip>

                        </Grid>
                      )}
                      {deployment.targetRegex && (
                        <Tooltip title={TOOLTIP_REGEX_DEPLOYMENT} aria-label="deployRegex" className={classes.globalDeployIcon}>
                          <Typography className={classes.headingTitle}>
                            {/* RegexIcon should be replaced with your actual regex deployment icon component */}
                            <RegexIcon className={classes.icon} />
                          </Typography>
                        </Tooltip>
                      )}

                      {!deployment.target && !deployment.targetRegex && deployment.targetMarket && (
                        <>
                          <Typography className={classes.headingTitle}>
                            <p className={classes.headingTitleElements}>{printCountTarget()}</p>
                          </Typography>
                          <Tooltip title={TOOLTIP_MARKET_DEPLOYMENT} aria-label="deployMarket">
                            <IconButton disabled>
                              {/* MarketIcon should be replaced with your actual market deployment icon component */}
                              <MarketIcon className={classes.icon} />
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                    </Grid>
                  )}
                />
              </ListItem>

            </Grid>

            {/* Delete */}
            <Grid container xs={1} sm={1} className={classes.dotsIcons}>
              <Grid container display="flex" direction="row" justifyContent="center" alignSelf="center">

                {!deleted && (
                  <Restricted to={[DELETE_DEPLOY_PERMISSION]}>
                    <Grid container xs={2} sm={1} alignItems="center">
                      <FormControlLabel
                        aria-label="Acknowledge"
                        onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => event.stopPropagation()}
                        className={classes.iconsBox}
                        control={(
                          <IconButton size="small" aria-label="delete" id={`DeleteIcon-${id}`} onClick={() => deleteDeploy(deployment.deploymentID)}>
                            <DeleteIcon />
                          </IconButton>
                        )}
                      />
                    </Grid>
                  </Restricted>
                )}

              </Grid>

            </Grid>

          </Grid>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>

          <Grid container className={classes.rootDetail}>
            <Grid container className={classes.overallRootContainer}>
              <Grid container alignContent="center" xs={12} sm={12} lg={8} className={classes.overallContainer}>
                {!overallStatus?.chartData || !overallStatus?.statusInfo || !deploymentList
                  ? (

                    <Grid container alignContent="center" justifyContent="center">
                      <Grid display="flex" direction="row" spacing={2}>
                        <Grid>
                          <Typography>
                            LOADING DATA
                          </Typography>
                        </Grid>
                        <Grid className={classes.gridChartProgress}>
                          <CircularProgress size={60} />
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (

                    <Grid container>
                      {/* Table status */}
                      <Grid
                        container
                        xs={12}
                        sm={12}
                        lg={
                          hasInsight ? 8 : 12
                        }
                        className={classes.detailItem}
                      >

                        <Typography variant="subtitle1" className={classes.mainDetailTitle}>
                          Overall Deployment Status
                        </Typography>
                        <Grid container xs={12} sm={12}>
                          <TableContainer style={{ width: '100%', overflowX: 'auto', padding: '8px' }}>
                            <Table aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Status</TableCell>
                                  <TableCell align="right">Devices</TableCell>
                                  <TableCell align="right">Percentage</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {/* Done */}
                                <TableRow key={overallStatus?.statusInfo[0].status} className={classes.tableRow}>

                                  <TableCell component="th" scope="row">
                                    {overallStatus?.statusInfo[0].status}
                                    {' '}
                                    <span
                                      className={classes.statusBullet}
                                      style={{ backgroundColor: statusColors.Done }}
                                    />
                                  </TableCell>

                                  <TableCell align="right">{overallStatus?.statusInfo[0].count}</TableCell>
                                  <TableCell align="right">{overallStatus?.statusInfo[0].percentage !== undefined ? `${overallStatus?.statusInfo[0].percentage}%` : ''}</TableCell>
                                </TableRow>

                                {/* Error */}
                                <TableRow key={overallStatus?.statusInfo[1].status} className={classes.tableRow}>

                                  {/* Error */}
                                  <TableCell component="th" scope="row">
                                    {overallStatus?.statusInfo[1].status}
                                    {' '}
                                    <span
                                      className={classes.statusBullet}
                                      style={{ backgroundColor: statusColors.Error }}
                                    />
                                  </TableCell>

                                  <TableCell align="right">{overallStatus?.statusInfo[1].count}</TableCell>
                                  <TableCell align="right">{overallStatus?.statusInfo[1].percentage !== undefined ? `${overallStatus?.statusInfo[1].percentage}%` : ''}</TableCell>
                                </TableRow>
                                {/* Executing */}
                                <TableRow key={overallStatus?.statusInfo[2].status} className={classes.tableRow}>

                                  {/* Executing */}
                                  <TableCell component="th" scope="row">
                                    In progress
                                    {' '}
                                    <span
                                      className={classes.statusBullet}
                                      style={{ backgroundColor: statusColors.Executing }}
                                    />

                                    <Tooltip title="All the assets that are in phases of request, validation, download, verification, confirmation or execution." aria-label="download">
                                      <InfoIcon className={classes.icon} />
                                    </Tooltip>
                                  </TableCell>

                                  <TableCell align="right">{overallStatus?.statusInfo[2].count}</TableCell>
                                  <TableCell align="right">{overallStatus?.statusInfo[2].percentage !== undefined ? `${overallStatus?.statusInfo[2].percentage}%` : ''}</TableCell>
                                </TableRow>
                                {/* Total */}
                                <TableRow key={overallStatus?.statusInfo[3].status} className={classes.tableRow}>

                                  {/* Total */}
                                  <TableCell component="th" scope="row">
                                    Total
                                    {' '}
                                    <Tooltip title="Total number of devices that initiated the software update process." aria-label="download">
                                      <InfoIcon className={classes.icon} />
                                    </Tooltip>

                                  </TableCell>

                                  <TableCell align="right">{overallStatus?.statusInfo[3].count}</TableCell>
                                </TableRow>

                              </TableBody>
                            </Table>
                          </TableContainer>

                        </Grid>
                      </Grid>

                      {/* insight */}
                      {hasInsight && (
                        <Grid container xs={12} sm={12} lg={4} justifyContent="center" className={classes.detailInsight} alignContent="center" alignItems="center">
                          <div className={classes.insightContainer}>
                            {overallStatus?.chartData && <Doughnut data={overallStatus?.chartData} options={doughnutOptions} />}
                          </div>

                        </Grid>
                      )}

                    </Grid>
                  )}
              </Grid>

              {/* Detail Grid */}
              <Grid item xs={12} sm={12} lg={4} className={classes.detailInfoItem}>
                <Grid className={classes.infoSection}>
                  <Typography variant="subtitle1" className={classes.headerDetailTitle}>
                    Information
                  </Typography>

                  <Grid container display="flex" direction="row" flex="1" className={classes.infoItems}>

                    <Grid container display="flex" direction="column" flex="1" xs={11}>
                      <Typography variant="body2" className={classes.headerValue}>
                        Deploy ID
                      </Typography>
                      <Typography variant="body2" className={classes.bodyValue}>
                        {deployment.deploymentID}
                      </Typography>

                    </Grid>
                    <Grid container xs={1}>
                      <IconButton onClick={() => copyToClipboard(deployment.deploymentID)}>
                        <FileCopyIcon />
                      </IconButton>
                    </Grid>

                  </Grid>

                  <Grid container display="flex" direction="row" className={classes.infoItems}>

                    <Grid item display="flex" direction="column" flex="1">
                      <Typography variant="body2" className={classes.headerValue}>
                        Actor
                      </Typography>
                      <Typography variant="body2" className={classes.bodyValue}>
                        {deployment.deployDescription?.userName || deployment.actor}
                      </Typography>

                    </Grid>

                    <IconButton onClick={() => copyToClipboard(deployment.deployDescription?.userName || deployment.actor)}>
                      <FileCopyIcon />
                    </IconButton>

                  </Grid>

                  {deployment.targetRegex && (
                    <div className={classes.infoItems}>
                      <Typography variant="body2" className={classes.headerValue}>
                        Target Regex
                      </Typography>
                      <Typography variant="body2" className={classes.infoValue}>
                        {deployment.targetRegex}
                      </Typography>
                    </div>
                  )}

                  {deployment.targetMarket && (
                    <div className={classes.infoItems}>
                      <Typography variant="body2" className={classes.headerValue}>
                        Target Market
                      </Typography>
                      <Typography variant="body2" className={classes.infoValue}>
                        {deployment.targetMarket}
                      </Typography>
                    </div>
                  )}

                  {deployment.exclude && (
                    <div className={classes.infoItems}>
                      <Typography variant="body2" className={classes.headerValue}>
                        Exclude
                      </Typography>
                      <Typography variant="body2" className={classes.infoValue}>
                        {deployment.exclude}
                      </Typography>
                    </div>
                  )}

                  {deployment.file.fileID && (

                    <Grid container display="flex" direction="row" className={classes.infoItems}>

                      <Grid item display="flex" direction="column" flex="1">
                        <Typography variant="body2" className={classes.headerValue}>
                          Asset
                        </Typography>
                        <Typography variant="body2" className={classes.bodyValue}>
                          Open the associated asset
                        </Typography>

                      </Grid>

                      <IconButton aria-label="openAsset" onClick={() => openAsset(deployment.file.fileID)}>
                        <OpenInNewIcon />
                      </IconButton>

                    </Grid>
                  )}

                </Grid>
              </Grid>
            </Grid>

            {/* Table Grid  */}
            <Grid container xs={12}>
              <Grid container display="flex" direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
                <Grid item>
                  <FormControl variant="filled" className={classes.detailSerialFilterStyle}>
                    <TextField
                      label="Filter by Serial"
                      variant="outlined"
                      value={filter}
                      onChange={handleFilterChange}
                      fullWidth
                      margin="normal"
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl variant="filled" className={classes.detailStatusFilterStyle}>

                    <InputLabel className={classes.paginationSizeLabel}>File Type Filter</InputLabel>
                    <Select
                      value={detailStatus}
                      onChange={handleDetailStatusChange}
                      displayEmpty
                      className={classes.selectPaginationSize}
                    >
                      <MenuItem value="NO-FILTER" key="NO-FILTER">NO FILTER</MenuItem>
                      {
                        // foreach with the values of the keys of the object
                        uniqueStatuses?.map((deploymentStatusItem) => (
                          <MenuItem value={deploymentStatusItem} key={deploymentStatusItem}>{deploymentStatusItem}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container>

                <TableContainer className={classes.tableStatus}>
                  <Table>
                    <TableHead className={classes.tableHeadDetailItem}>
                      <TableRow>
                        <TableCell className={classes.tableHead}>
                          Device Serial
                          <IconButton onClick={() => handleRequestSort('deviceSerial')}>

                            {(() => {
                              if (orderBy === 'deviceSerial') {
                                return order === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />;
                              }
                              return <SortIcon />;
                            })()}
                          </IconButton>
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Last Update Request
                          <IconButton onClick={() => handleRequestSort('lastUpdateRequest')}>
                            {(() => {
                              if (orderBy === 'lastUpdateRequest') {
                                return order === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />;
                              } return <SortIcon />;
                            })()}
                          </IconButton>
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Status
                          <IconButton onClick={() => handleRequestSort('status')}>
                            {(() => {
                              if (orderBy === 'status') {
                                return order === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />;
                              } return <SortIcon />;
                            })()}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className={classes.tableBodyDetailItem}>
                      {sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                        <TableRow key={row.id}>
                          <TableCell>{row.deviceSerial}</TableCell>
                          <TableCell>{row.lastUpdateRequest}</TableCell>
                          <TableCell>{row.status}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={filteredData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Grid>

            </Grid>

          </Grid>

        </AccordionDetails>
      </Accordion>
    </div>

  );
}
DeploymentAccordion.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  deployment: PropTypes.any,
  id: PropTypes.string,
  deleted: PropTypes.bool,
  deleteDeploy: PropTypes.func,
};
DeploymentAccordion.defaultProps = {
  deployment: undefined,
  id: undefined,
  deleted: false,
  deleteDeploy: undefined,
};
