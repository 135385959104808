import {
  createContext,
} from 'react';

const SelectedAssetsContext = createContext({
  selectedAssets: [],
  setSelectedAssets: () => {},
});

export default SelectedAssetsContext;
