import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Auth } from 'aws-amplify';
import { useProject } from '../config/ProjectContext';
import GraphqlService from '../service/graphqlService';
import { useSnackbar } from '../providers/SnackbarContext';

const useCSVDeviceRawDataExport = (params, filters, dateIni, dateEnd, rangeDates, object) => {
  const { project } = useProject();
  const [loading, setLoading] = useState(false);

  const openSnackBar = useSnackbar();

  // Set up the mutation outside of the exportToCSV function
  const createNotificationMutation = useMutation((variables) => GraphqlService.createNotification(variables.email, variables.notificationTitle, variables.notificationDescription));

  const exportToCSV = async () => {
    try {
      setLoading(true); // Indicate that the process has started
      const userToken = await Auth.currentAuthenticatedUser();
      const email = userToken?.signInUserSession?.idToken?.payload?.identities?.shift().userId || userToken?.attributes?.email;
      openSnackBar('CSV file is being generated', 'info');
      // Use the mutation here
      await createNotificationMutation.mutateAsync({
        email,
        notificationTitle: 'CSV file is being generated',
        notificationDescription: 'Your CSV file is being generated. You will receive a notification with the download link shortly.',
      });

      await GraphqlService.getSandboxObjectCSV(
        project.code, object, JSON.stringify(params), filters, dateIni, dateEnd, rangeDates,
      );
    } catch (error) {
      throw new Error(error.message);
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  return {
    exportToCSV,
    loading,
  };
};

export default useCSVDeviceRawDataExport;
