import { useContext, useState } from 'react';
import AuthorizationContext from './AuthorizationContext';

const usePermission = (permission) => {
  const [loading, setLoading] = useState(true);
  const [allowed, setAllowed] = useState(false);

  const { isAllowedTo } = useContext(AuthorizationContext);

  async function waitAllowed() {
    if (Array.isArray(permission)) {
      let isAllowed = false;
      try {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < permission.length; i++) {
          // eslint-disable-next-line no-await-in-loop
          const result = await isAllowedTo(permission[i]);
          if (result) {
            isAllowed = true;
            break;
          }
        }
      } catch (err) {
        console.debug('isAllowed error', err);
      }
      setLoading(false);
      setAllowed(isAllowed);
    } else {
      let isAllowed = false;
      try {
        isAllowed = await isAllowedTo(permission);
      } catch (err) {
        console.debug('isAllowed error', err);
      }
      setLoading(false);
      setAllowed(isAllowed);
    }
  }

  waitAllowed();

  return [loading, allowed];
};

export default usePermission;
