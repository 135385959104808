/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Grid, Link, TextField, makeStyles, InputLabel, RadioGroup, FormControlLabel, Radio, FormLabel,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { React } from 'react';
import { Alert } from '@material-ui/lab';
import DateRangePicker from './DateRangePicker';
import SearchButton from './SearchButton';
import JSONBuilder from '../JSONBuilder/JSONBuilder';

export default function JSONBuilderFilters({
  selectedFilters, setSelectedFilters, submitting,
  selectedStartDate, setSelectedStartDate, selectedStartTime,
  setSelectedStartTime, selectedEndDate, setSelectedEndDate,
  selectedEndTime, setSelectedEndTime, id,
  datesOptional, messageTypeVisible,
  filtersVisible, deviceSerialVisible,
  linkVisible, linkText, linkURL,
  deviceSerial, setDeviceSerial,
  messageType, setMessageType,
  deviceSerialError, maxDaysBetweenDates, subtitle,
  filtersComponents, jsonColumns, onApplyDateRange, setDateRangePickerVisibility, setFilterError,
  filterError,
}) {
  const useStyles = makeStyles((theme) => ({
    topContent: {
      padding: 24,
    },

    bottomContent: {
      paddingTop: 16,
      paddingBottom: 12,
    },
    topField: {
      minHeight: 76,
    },
    bottomLeftFields: {
      paddingLeft: 12,
    },
    radioGroup: {
      paddingLeft: 12,
      width: '100%',
      backgroundColor: 'white',
    },
    radioGrid: {
      marginTop: 16,
      marginRight: 8,
      height: 146,
      width: '100%',
      borderTopRightRadius: 4,
      borderTopLeftRadius: 4,
    },
    bottomRightFields: {
      paddingLeft: 12,
    },
    picker: {
      '& .MuiIconButton-root': {
        color: 'black',
      },
      width: '100%',
    },
    errorAlert: {
      paddingTop: 0,
      paddingBottom: 0,
      marginBottom: 4,
    },
    bottomRightButton: {
      textAlign: 'right',
      alignSelf: 'flex-end',
      borderRadius: 12,
      marginRight: 12,
    },
    linkClass: {
      paddingTop: 15,
    },
    inputDescriptionLabel: {
      font: theme.typography.h4.font,
      fontSize: 13,
      paddingLeft: 12,
      paddingTop: 10,
      paddingBottom: 8,
    },
    radioButton: {
      paddingTop: 4,
      paddingBottom: 4,
      paddingLeft: 8,
    },
    inputSubtitleLabel: {
      font: theme.typography.h4.font,
      color: theme.palette.primary.text,
    },
    JSONBuilder: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
  }));

  const classes = useStyles();

  const handleDateChange = (startDate, endDate) => {
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
  };
  return (
    <Grid
      item
      container
      spacing={0}
      direction="column"
      className={classes.topContent}
    >
      {subtitle !== '' && (
        <Grid>
          <InputLabel
            className={classes.inputSubtitleLabel}
          >
            {subtitle}
          </InputLabel>
        </Grid>
      )}
      {filtersComponents.map((component) => (
        component.type === 'radio' && (
          <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" xs={2} className={classes.radioGrid}>
            <FormLabel className={classes.inputDescriptionLabel}>{component.label}</FormLabel>
            <RadioGroup
              className={classes.radioGroup}
              onChange={component.handleChange}
              defaultValue={component.defaultValue}
            >
              {component.values.map((value) => (
                <FormControlLabel
                  id={`btnRadio${id}${value.label}`}
                  className={classes.radioButton}
                  value={value.value}
                  control={<Radio />}
                  label={value.label}
                />
              ))}
            </RadioGroup>
          </Grid>
        )
      ))}
      <Grid
        item
        container
        direction="row"
        className={classes.bottomContent}
        spacing={1}
      >
        <Grid container xs={12} md={6}>
          <Grid item xs={12} className={classes.bottomLeftFields}>
            <DateRangePicker
              initialStartDate={new Date()}
              initialEndDate={new Date()}
              onDateChange={handleDateChange}
              setSelectedStartDate={setSelectedStartDate}
              selectedStartDate={selectedStartDate}
              setSelectedStartTime={setSelectedStartTime}
              selectedStartTime={selectedStartTime}
              setSelectedEndDate={setSelectedEndDate}
              selectedEndDate={selectedEndDate}
              setSelectedEndTime={setSelectedEndTime}
              selectedEndTime={selectedEndTime}
              datesOptional={datesOptional}
              maxDaysBetweenDates={maxDaysBetweenDates}
              setVisibility={setDateRangePickerVisibility}
            />
          </Grid>
        </Grid>

        {deviceSerialVisible && (
          <Grid item container justifyContent="center" xs={filtersVisible && filtersComponents.length > 0 ? 12 : 6} py={3}>
            <Grid item xs={12} className={classes.bottomLeftFields}>
              <TextField
                id={`input${id}DeviceSerial`}
                variant="filled"
                label="Device Serial"
                value={deviceSerial}
                onChange={(event) => {
                  setDeviceSerial(event.target.value);
                }}
                orientation="landscape"
                inputVariant="filled"
                className={classes.picker}
                color="secondary"
              />
              {deviceSerialError && <Alert severity="error" className={classes.errorAlert}>Cannot be empty</Alert>}
            </Grid>
          </Grid>
        )}
        {messageTypeVisible && (
          <Grid item container justifyContent="center" xs={filtersVisible && filtersComponents.length > 0 ? 12 : 6} py={3}>
            <Grid item xs={12} className={classes.bottomLeftFields}>
              <TextField
                id={`input${id}MessageType`}
                variant="filled"
                label="Message type"
                value={messageType}
                onChange={(event) => {
                  setMessageType(event.target.value);
                }}
                orientation="landscape"
                inputVariant="filled"
                className={classes.picker}
                color="secondary"
              />
            </Grid>
          </Grid>
        )}

        <div className={classes.JSONBuilder}>

          {filtersVisible && (

            <JSONBuilder
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              submitting={submitting}
              id={id}
              jsonColumns={jsonColumns}
              setFilterError={setFilterError}
              filterError={filterError}
            />

          )}
        </div>

        <Grid item container justifyContent={linkVisible ? 'space-between' : 'flex-end'} xs={12} className={classes.bottomRightButton}>
          {linkVisible && (
            <Link
              href={linkURL}
              underline="always"
              target="_blank"
              className={classes.linkClass}
            >
              {linkText}
            </Link>
          )}
          <SearchButton
            id={`btn${id}Search`}
            type="submit"
            className={classes.bottomRightButton}
            disabled={filterError !== ''}
          >
            SEARCH
          </SearchButton>

        </Grid>

      </Grid>

    </Grid>
  );
}
JSONBuilderFilters.propTypes = {
  selectedFilters: PropTypes.string.isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  selectedStartDate: PropTypes.string.isRequired,
  setSelectedStartDate: PropTypes.func.isRequired,
  selectedStartTime: PropTypes.string.isRequired,
  setSelectedStartTime: PropTypes.func.isRequired,
  selectedEndDate: PropTypes.string.isRequired,
  setSelectedEndDate: PropTypes.func.isRequired,
  selectedEndTime: PropTypes.string.isRequired,
  setSelectedEndTime: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  filtersVisible: PropTypes.bool.isRequired,
  deviceSerialVisible: PropTypes.bool.isRequired,
  linkVisible: PropTypes.bool,
  linkText: PropTypes.string,
  linkURL: PropTypes.string,
  messageTypeVisible: PropTypes.bool,
  datesOptional: PropTypes.bool,
  deviceSerial: PropTypes.string,
  setDeviceSerial: PropTypes.func,
  messageType: PropTypes.string,
  setMessageType: PropTypes.func,
  deviceSerialError: PropTypes.bool,
  maxDaysBetweenDates: PropTypes.number,
  subtitle: PropTypes.string,
  filtersComponents: PropTypes.arrayOf(PropTypes.object),
  jsonColumns: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  onApplyDateRange: PropTypes.func,
  setDateRangePickerVisibility: PropTypes.bool,
  setFilterError: PropTypes.func,
  filterError: PropTypes.string,
};

JSONBuilderFilters.defaultProps = {
  linkVisible: false,
  linkText: '',
  linkURL: '',
  messageTypeVisible: false,
  datesOptional: true,
  deviceSerial: '',
  setDeviceSerial: () => { },
  messageType: '',
  setMessageType: () => { },
  deviceSerialError: false,
  maxDaysBetweenDates: 30,
  subtitle: '',
  filtersComponents: [],
  jsonColumns: [],
  onApplyDateRange: () => { },
  setDateRangePickerVisibility: false,
  setFilterError: () => { },
  filterError: '',
};
