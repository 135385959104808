import React from 'react';
import PropTypes from 'prop-types';
import usePermission from './permissionHook';

const Restricted = ({
  to, fallback, loadingComponent, children,
}) => {
  const [loading, allowed] = usePermission(to);

  if (loading) {
    return <>{loadingComponent}</>;
  }

  // If the user has that permission, render the children
  if (allowed) {
    return <>{children}</>;
  }

  // Otherwise, render the fallback
  return <>{fallback}</>;
};
Restricted.propTypes = {
  to: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node.isRequired,
  fallback: PropTypes.func || PropTypes.object,
  loadingComponent: PropTypes.func || PropTypes.object,
};
Restricted.defaultProps = {
  fallback: () => { },
  loadingComponent: () => { },
  to: [],
};

export default Restricted;
