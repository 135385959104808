import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {
  Chip, IconButton, Paper, Typography, makeStyles, Grid, TextField,
} from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import { Button } from '@aws-amplify/ui-react';
import { Alert } from '@material-ui/lab';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

// Styles for the components
const useStyles = makeStyles((theme) => ({
  dateRangePicker: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  chipsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    marginTop: '10px',
  },
  chip: {
    margin: '2px',
    backgroundColor: 'white',
  },
  mainLabel: {
    backgroundColor: 'white',
    paddingRight: '15px',
    padddingLeft: '5px',
    borderRadius: '5px',
    alignContent: 'center',
    alignItems: 'center',
    display: 'flex',
    height: 56,
  },
  hiddenContainer: {
    position: 'absolute',
    zIndex: '100',
    backgroundColor: 'white',
    padding: '0px',
    borderRadius: '5px',
    width: '400px',
    marginTop: '80px',
    // mobile view
    '@media (max-width: 600px)': {
      width: '300px',
    },
  },
  selectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

  },
  datePicker1: {
    marginLeft: '10px',
  },
  datePicker2: {
    marginLeft: '10px',
  },
  quickDatesContainer: {
    padding: '10px',
    backgroundColor: '#F1F4F6',
  },
  updateButton: {
    backgroundColor: theme.palette.primary.button,
    color: theme.palette.common.white,
    width: '90%',
    margin: '10px',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: '#214179',
      color: theme.palette.common.white,
    },
    '&:disabled': {
      backgroundColor: '#F1F4F6',
      color: 'gray',
    },
  },
  timePicker: {
    marginTop: '8px',
  },
}));

const DateRangePicker = ({
  onDateChange,
  setSelectedStartDate,
  selectedStartDate,
  setSelectedStartTime,
  selectedStartTime,
  setSelectedEndDate,
  selectedEndDate,
  setSelectedEndTime,
  selectedEndTime,
  datesOptional,
  maxDaysBetweenDates,
  setVisibility,
}) => {
  const classes = useStyles();

  const [pickerVisible, setPickerVisible] = useState(false);

  const [greaterSmallerDateError, setGreaterSmallerDateError] = useState(false);
  const [moreThanMaxDaysDateError, setMoreThanMaxDaysDateError] = useState(false);

  const validateStartDate = (startDate, endDate) => {
    if (!startDate.isValid()) {
      return false;
    }

    if (endDate && moment(startDate).isAfter(endDate)) {
      setGreaterSmallerDateError(true);
      return false;
    }
    setGreaterSmallerDateError(false);

    if (endDate && moment(endDate).diff(startDate, 'days') >= maxDaysBetweenDates && maxDaysBetweenDates !== 0) {
      setMoreThanMaxDaysDateError(true);
      return false;
    }
    setMoreThanMaxDaysDateError(false);

    return true;
  };

  useEffect(() => {
    setPickerVisible(false);
  }, [setVisibility]);

  const validateEndDate = (startDate, endDate) => {
    if (!endDate.isValid()) {
      return false;
    }

    if (startDate && moment(endDate).isBefore(startDate)) {
      setGreaterSmallerDateError(true);
      return false;
    }
    setGreaterSmallerDateError(false);

    if (startDate && moment(endDate).diff(startDate, 'days') >= maxDaysBetweenDates && maxDaysBetweenDates !== 0) {
      setMoreThanMaxDaysDateError(true);
      return false;
    }
    setMoreThanMaxDaysDateError(false);
    return true;
  };

  const handleStartDateChange = (date) => {
    validateStartDate(moment(date), selectedEndDate);
    setSelectedStartDate(moment(date));
    onDateChange(moment(date), selectedEndDate);
  };

  const handleEndDateChange = (date) => {
    validateEndDate(selectedStartDate, moment(date));
    setSelectedEndDate(moment(date));
    onDateChange(selectedStartDate, moment(date));
  };

  const quickDateSelection = (period) => {
    let start; let
      end;
    switch (period) {
      case 'lastWeek':
        start = moment().subtract(1, 'months').startOf('isoWeek');
        end = moment().subtract(1, 'months').endOf('isoWeek');
        break;
      case 'thisWeek':
        start = moment().startOf('isoWeek');
        end = moment().endOf('isoWeek');
        break;
      case 'thisMonth':
        start = moment().startOf('month');
        end = moment().endOf('month');
        break;
      default:
        start = moment().subtract(period, 'months').startOf('month');
        end = moment().subtract(period, 'months').endOf('month');
    }
    // if start and date are more than 30 days, set the end date to 30 days from the start date
    if (moment(end).diff(start, 'days') >= maxDaysBetweenDates) {
      end = moment(start).add(maxDaysBetweenDates - 1, 'days');
    }
    if (!validateStartDate(start, end) || !validateEndDate(start, end)) {
      return;
    }
    setSelectedStartDate(start);
    setSelectedEndDate(end);
    onDateChange(start, end);
  };

  const handlePickerVisibility = () => {
    setPickerVisible(!pickerVisible);

    setGreaterSmallerDateError(false);
    setMoreThanMaxDaysDateError(false);
  };

  const applyChange = () => {
    if (!validateStartDate(selectedStartDate, selectedEndDate) || !validateEndDate(selectedStartDate, selectedEndDate)) {
      return;
    }
    setPickerVisible(false);
  };

  const formatDateRangeLabel = (start, end) => `${start?.format('MMM DD, YYYY')} - ${end?.format('MMM DD, YYYY')}`;

  //

  // Generating chips for the past 5 months
  const monthsChips = Array.from({ length: 5 }, (_, i) => {
    const monthDate = moment().subtract(i + 1, 'months');
    return (
      <Chip
        key={i}
        label={monthDate.format('MMMM YYYY')}
        onClick={() => quickDateSelection(i + 1)}
        className={classes.chip}
      />
    );
  });

  const handleChangeSelectedStartTime = (event) => {
    const startDate = moment(selectedStartDate).set({
      hour: moment(event.target.value, 'HH:mm').get('hour'),
      minute: moment(event.target.value, 'HH:mm').get('minute'),
    });

    if (!validateStartDate(startDate, selectedEndDate)) {
      return;
    }
    setSelectedStartDate(startDate);
    setSelectedStartTime(event.target.value);
  };

  const handleChangeSelectedEndTime = (event) => {
    // event.target.value, 'HH:mm'
    const endDate = moment(selectedEndDate).set({
      hour: moment(event.target.value, 'HH:mm').get('hour'),
      minute: moment(event.target.value, 'HH:mm').get('minute'),
    });

    if (!validateEndDate(selectedStartDate, endDate)) {
      return;
    }

    setSelectedEndDate(endDate);
    setSelectedEndTime(event.target.value);
  };

  return (

    <div id="dateRangePicker" className={classes.dateRangePicker}>
      <Typography variant="body2" color="textSecondary" component="p">Dates</Typography>
      <div display="flex" className={classes.mainLabel}>
        <IconButton onClick={handlePickerVisibility}>
          <EventIcon />
        </IconButton>
        <span onClick={handlePickerVisibility} tabIndex="0" onKeyPress={handlePickerVisibility} role="button" style={{ cursor: 'pointer' }} id="mainlabel">{formatDateRangeLabel(selectedStartDate, selectedEndDate)}</span>
        {pickerVisible
          ? (
            <IconButton onClick={handlePickerVisibility}>
              <ArrowDropUpIcon />
            </IconButton>
          )
          : (
            <IconButton onClick={handlePickerVisibility}>
              <ArrowDropDownIcon />
            </IconButton>
          )}
      </div>

      {pickerVisible && (
        <Paper className={classes.hiddenContainer}>
          <div className={classes.selectionContainer}>

            <Grid container display="flex" direction="column" justifyContent="center" alignContent="center" alignItems="center">
              <Grid container display="flex" justifyContent="center" alignContent="center" alignItems="center" spacing={2}>
                <Grid item xs={7}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      autoOk
                      variant="inline"
                      disableToolbar
                      margin="normal"
                      format="DD-MM-YY"
                      label="Start Date"
                      value={selectedStartDate}
                      onChange={handleStartDateChange}
                      className={classes.datePicker1}
                      inputVariant="outlined"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={5} className={classes.timePicker}>
                  <TextField
                    id="inputStartTimeFrom"
                    variant="outlined"
                    value={selectedStartTime}
                    onChange={handleChangeSelectedStartTime}
                    orientation="landscape"
                    inputVariant="outlined"
                    className={classes.picker}
                    type="time"
                    color="secondary"
                  />
                </Grid>
              </Grid>
              <Grid container display="flex" justifyContent="center" alignContent="center" alignItems="center" spacing={2}>
                <Grid item xs={7}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      autoOk
                      variant="inline"
                      disableToolbar
                      margin="normal"
                      format="DD-MM-YY"
                      label="End Date"
                      value={selectedEndDate}
                      onChange={handleEndDateChange}
                      className={classes.datePicker2}
                      inputVariant="outlined"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={5} className={classes.timePicker}>
                  <TextField
                    id="inputEndTimeTo"
                    variant="outlined"
                    value={selectedEndTime}
                    onChange={handleChangeSelectedEndTime}
                    orientation="landscape"
                    inputVariant="outlined"
                    className={classes.picker}
                    type="time"
                    color="secondary"
                  />
                </Grid>
              </Grid>
            </Grid>
            {!selectedStartDate && !datesOptional && <Alert severity="error" className={classes.errorAlert}>Cannot be empty</Alert>}
            {greaterSmallerDateError && <Alert severity="error" className={classes.errorAlert}>From date has to be before the To date</Alert>}
            {moreThanMaxDaysDateError && <Alert severity="error" className={classes.errorAlert}>{`Dates need to be max. ${maxDaysBetweenDates} days apart`}</Alert>}
            <Button
              className={classes.updateButton}
              onClick={applyChange}
              color="secondary"
              variant="contained"
              disabled={!selectedStartDate || !selectedEndDate || greaterSmallerDateError || moreThanMaxDaysDateError}
            >
              Apply
            </Button>
            <div className={classes.quickDatesContainer}>
              <Typography variant="caption">QUICK DATES</Typography>
              <div className={classes.chipsContainer}>
                <Chip label="Last Week" onClick={() => quickDateSelection('lastWeek')} className={classes.chip} />
                <Chip label="This Week" onClick={() => quickDateSelection('thisWeek')} className={classes.chip} />
                <Chip label="This Month" onClick={() => quickDateSelection('thisMonth')} className={classes.chip} />
                {monthsChips}
              </div>
            </div>

          </div>
        </Paper>
      )}
    </div>
  );
};

DateRangePicker.propTypes = {
  onDateChange: PropTypes.func.isRequired,
  setSelectedStartDate: PropTypes.func.isRequired,
  selectedStartDate: PropTypes.string.isRequired,
  selectedStartTime: PropTypes.string.isRequired,
  setSelectedStartTime: PropTypes.func.isRequired,
  selectedEndDate: PropTypes.string.isRequired,
  setSelectedEndDate: PropTypes.func.isRequired,
  selectedEndTime: PropTypes.string.isRequired,
  setSelectedEndTime: PropTypes.func.isRequired,
  datesOptional: PropTypes.bool,
  maxDaysBetweenDates: PropTypes.number,
  setVisibility: PropTypes.bool,
};

DateRangePicker.defaultProps = {
  datesOptional: true,
  maxDaysBetweenDates: 30,
  setVisibility: true,
};

export default DateRangePicker;
