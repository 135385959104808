/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import './App.css';
import React, { useEffect } from 'react';
import { HashRouter } from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react';
import { createTheme, ThemeProvider } from '@material-ui/core';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import Layout from './components/Layout';
import { loadSharedHooks } from './config/hooksLoader';
import { SnackbarProvider } from './providers/SnackbarContext';
import { target } from './config/awsExports';
import { ProjectProvider } from './config/ProjectContext';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#295299',
      subMain: '#295299',
      text: '#324149',
      button: '#295299',
      buttonImportant: '#295299 !important',
      banner: 'rgba(239, 100, 34, 0.7)',
      bannerText: '#ffffff',
      bannerButton: '#ffffff',
      labelDescription: '#000000de',
      labelHeader: '#808080',
      bottomBorder: '#CCCCCC',
      footer: '#63513D',
      subFooter: '#E6E6E6',
      modelPicker: '#4971B7',
      deleteButton: '#DD3232',
      ligthButton: '#E2E8F3',
    },
    secondary: {
      main: '#295299',
      subMain: '#E2E8F3',
      text: '#324149',
      contrastText: '#000000',
      button: '#000',
      subText: '#333333',
    },
    error: {
      main: '#DD3232',
      statistics: '#D50000',
    },
    warning: {
      main: '#FAAF19',
      statistics: '#FA6119',
    },
    success: {
      main: '#3D8705',
      statistics: '#3F9C35',
    },
    published: {
      text: '#00A35E',
      background: '#E5F5EE',
    },
    notPublished: {
      text: '#808080',
      background: '#EFEFEF',
    },
    globallyDeployed: {
      text: '#277777',
      background: 'rgba(39,119,119,0.1)',
    },
    cancelled: {
      text: '#DD3232',
      background: 'rgba(221,50,50,0.25)',
    },
    background: {
      paper: '#F5F6FA',
      default: '#F5F6FA',
      tableHeader: 'rgba(239, 100, 34, 0.7)',
      separator: '#A1A1A1',
      background: '#F5F6FA',
      dropzone: '#979797',
      black: '#000000',
      typography: '#F5F6FA',
      form: '#E2EAF9',
      accentForm: '#cbd2e0',
      footer: '#F2F2F2',
      tabContent: '#F5F6FA',
      header: '#F5F6FA',
    },
    colors: {
      statistics: {
        color1: '#0066A1',
        color2: '#EF6422',
        color3: '#3F9C35',
        color4: '#FCBF10',
        color5: '#6D8DC5',
        color6: '#C40000',
        color7: '#F177D9',
        color8: '#00A59C',
        color9: '#0066A1',
        color10: '#277777',
        color11: '#FF5757',
        color12: '#9AC4DC',
        color13: '#D8B4E2',
        color14: '#588B8B',
        color15: '#98C1D9',
        color16: '#C1EDCC',
        color17: '#F5DD90',
        color18: '#90708C',
        color19: '#FFDBDA',
        color20: '#A1E5AB',
        color21: '#D2AB99',
        color22: '#90BAAD',
        color23: '#DEC0F1',
        color24: '#D4F2D2',
        color25: '#E6AA68',
      },
    },
  },
  typography: {
    fontFamily: ['Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'].join(','),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    fontWeightBolder: 700,
    h1: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: '1.3rem',
      lineHeight: 1.167,
      letterSpacing: '-0.01562em',
    },
    h2: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '1.2rem',
      lineHeight: 1.167,
      letterSpacing: '-0.01562em',
    },
    h3: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '1.1rem',
      lineHeight: 1.167,
      letterSpacing: '-0.01562em',
    },
    h4: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 300,
      fontSize: '1.1rem',
      lineHeight: 1.167,
      letterSpacing: '-0.01562em',
    },
    h5: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '0',
    },
    h6: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: '500',
      fontSize: '20px',
      lineHeight: '24px',
      letterSpacing: '0',
    },
    h7: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '20px',
      letterSpacing: '0',
    },
    h8: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0',
    },
    h9: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: '500',
      fontSize: '14px !important',
      lineHeight: '16px',
      letterSpacing: '0',
    },
    h10: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: '500',
      fontSize: '28px !important',
      lineHeight: '33px',
      letterSpacing: '0',
    },
    h11: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '19px',
      letterSpacing: '0',
    },
    h12: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 'bold',
      fontSize: '16px',
      lineHeight: '19px',
      letterSpacing: '0',
    },
    releaseTableHeader: {
      lineHeight: 0,
      fontSize: 12,
    },
    releaseTableText: {
      fontSize: 14,
    },
  },
  customShadows: {
    wholeShadow: '0px 0px 0px 0px rgba(0,0,0,0.24)',
    rightShadow: '0 0 6px 0 rgba(0,0,0,0.24)',
    wholeShadowAllSides: '0px 0px 4px 0px rgba(0,0,0,0.24)',
    noShadow: '0px 0px 0px 0px rgba(0,0,0,0.24)',
    regularShadow: '0 1px 2px 0 rgba(0,0,0,0.2), 0 1px 5px 0 rgba(0,0,0,0.13)',
  },
  overrides: {
    MuiListItem: {
      root: {
        '&$selected, $selected:hover': {
          color: '#295299',
          backgroundColor: '#F6F4F2',
          '& g': {
            // fill: '#8F7247',
            fill: '#295299',
          },
          '& .MuiTypography-displayBlock': {
            fontWeight: 500,
          },
        },
      },
    },
    MuiListItemIcon: {
      root: {
        color: '',
      },
    },
    MuiFilledInput: {
      input: {
        '&::placeholder': {
          color: '#666666',
          opacity: 1,
        },
      },
    },
    MuiInputBase: {
      input: {
        '&:autofill': {
          WebkitBoxShadow: '0 0 0 100px white inset',
        },
        '&:-webkit-autofill': {
          WebkitBoxShadow: '0 0 0 100px white inset',
        },
      },
    },
    MuiAccordion: {
      rounded: {
        borderRadius: 12,
        '&:first-child': {
          borderTopLeftRadius: 12,
          borderTopRightRadius: 12,
        },
      },
    },
    MuiAccordionSummary: {
      content: {
        margin: '10px 0',
        '&.Mui-expanded': {
          padding: 0,
          margin: 0,
        },
      },
    },
  },
});

const queryClient = new QueryClient();
function App() {
  loadSharedHooks();

  function insertGTM(gtmId) {
    const script = document.createElement('script');
    script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push(
    {'gtm.start': new Date().getTime(),event:'gtm.js'});
    var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
    j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
    f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer', '${gtmId}');`;
    document.head.appendChild(script);

    const noscript = document.createElement('noscript');
    const iframe = document.createElement('iframe');
    iframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmId}`;
    iframe.height = '0';
    iframe.width = '0';
    iframe.style.display = 'none';
    iframe.style.visibility = 'hidden';
    noscript.appendChild(iframe);
    document.body.insertBefore(noscript, document.body.firstChild);
  }
  useEffect(() => {
    document.title = `Consumer IoT Portal (${target})`;
    // Use the GTM ID from the environment variables

    if (target !== 'DEV') {
      const gtmId = target === 'STAG' ? 'GTM-NM6RPZPX' : 'GTM-T3MTFFG5';
      insertGTM(gtmId);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <HashRouter>
        <ProjectProvider>
          <Authenticator.Provider>
            <SnackbarProvider>
              <QueryClientProvider client={queryClient}>
                <Layout />
              </QueryClientProvider>
            </SnackbarProvider>
          </Authenticator.Provider>
        </ProjectProvider>
      </HashRouter>
    </ThemeProvider>
  );
}

export default App;
