/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  TextField, Button, MenuItem, Grid, Card, CardContent, Typography,
} from '@material-ui/core';
import {
  KeyboardDatePicker, MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import DateUtils from '@date-io/moment';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { v4 as uuidv4 } from 'uuid';

import { ReactComponent as CalendarIcon } from '../../assets/ic_calendar.svg';

const operators = [
  { value: 'EQ', label: 'Is equal' },
  { value: 'NE', label: 'Is not equal' },
  { value: 'LT', label: 'Is lower than ' },
  { value: 'LE', label: 'Is lower or equal than' },
  { value: 'GT', label: 'Is greater than' },
  { value: 'GE', label: 'Is greater or equal than' },
  { value: 'IN', label: 'Is in' },
];

const booleanOperators = operators.filter((o) => o.value === 'EQ' || o.value === 'NE');

const stringOperators = operators.filter((o) => o.value === 'EQ' || o.value === 'NE' || o.value === 'IN');

const dateOperators = operators.filter((o) => o.value !== 'IN');

export const valueTypes = {
  STRING: 'STRING',
  NUMBER: 'NUMBER',
  DATE: 'DATE',
  BOOLEAN: 'BOOLEAN',
  LIST: 'LIST',
};

const useStyles = makeStyles((theme) => ({
  filterButton: {
    backgroundColor: theme.palette.primary.ligthButton,
    color: theme.palette.common.black,
    margin: theme.spacing(1), // Adjust this value
    padding: theme.spacing(1),
    borderRadius: 12,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
  filterItem: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: '100%',
  },
  dateGrid: {
    width: '100%',
    paddingBottom: '16px',
  },
  filterField: {
    marginTop: '10px',
  },
  root: {
    width: '100%',
    padding: theme.spacing(2),
  },
  tabContent: {
    width: '100%',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.tabContent,
    borderRadius: '12px',

  },
  accordionDetails: {
    width: '100%',
  },
  jsonTitle: {
    color: theme.palette.primary.black,
  },
  andItem: {
    paddingRight: '10px',
    fontWeight: 'bold',
    fontSize: '1.2em',
  },

}));

function JSONBuilder({
  setSelectedFilters, id, jsonColumns, setFilterError,
}) {
  const classes = useStyles();
  // array of filters that has been added (column, operator, value)
  const [filters, setFilters] = useState([]);

  // set a default date for the date picker
  const [selectedStartDate, setSelectedStartDate] = useState(moment());

  // filters inputs we can see in the screen
  const [inputFilters, setInputFilters] = useState([
    {
      column: '', operator: '', value: '', id: 0,
    },
  ]);

  const [selectorError, setSelectorError] = useState('');

  const [columnsToSelect, setColumnsToSelect] = useState(jsonColumns);
  const filterIsEmpty = (filter) => filter.column === '' || filter.operator === '' || filter.value === '';

  const createJsonFromFilters = () => filters.map((filter) => {
    // if operator is in, create transform filter value from value1, value2, to ["value1","value2"]
    if (filter.operator === 'IN') {
      return {
        column: filter.column,
        operator: filter.operator,
        value: filter.value.split(',').map((v) => v.trim()),
      };
    }

    return {
      column: filter.column,
      operator: filter.operator,
      value: filter.value,
    };
  });

  const addFilter = (filter) => {
    if (filterIsEmpty(filter)) {
      return;
    }
    // add the filter to the list of filters
    setFilters((prevFilters) => [...prevFilters || [], { ...filter }]);
    // add input filters
    setInputFilters((prevFilters) => [...prevFilters, {
      column: '', operator: '', value: '', id: uuidv4(),
    }]);
  };

  const deleteFilter = (filter) => {
    // delete the filter from the list of filters
    setFilters((prevFilters) => prevFilters.filter((f) => f.id !== filter.id));
    // delete the input filter
    setInputFilters((prevFilters) => prevFilters.filter((f) => f.id !== filter.id));
    // set selected to false in the columns to select
    setColumnsToSelect(
      columnsToSelect.map((column) => (column.id === filter.column
        ? { ...column, selected: false }
        : column)),
    );

    // if a mandatory filter has been deleted, it will be added automatically
    if (jsonColumns.find((c) => c.id === filter.column)?.isMandatory) {
      setInputFilters((prevFilters) => [...prevFilters, {
        column: filter.column, operator: '', value: '', id: uuidv4(), isMandatory: true, maxValues: jsonColumns.find((c) => c.id === filter.column)?.maxValues,
      }]);
      // set selected to false in the columns to select
      setColumnsToSelect(
        columnsToSelect.map((column) => (column.isMandatory
          ? { ...column, selected: true }
          : column)),
      );
    }

    // delete the filters that does not have column
    setFilters((prevFilters) => prevFilters.filter((f) => f.column !== ''));
    setInputFilters((prevFilters) => prevFilters.filter((f) => f.column !== ''));
  };

  const updateFilter = (field, filterValue, inputFilter) => {
    if (inputFilter.maxValues && field === 'value' && filterValue.split(',').length > inputFilter.maxValues) {
      setSelectorError(`You can only select up to ${inputFilter.maxValues} values`);
    } else setSelectorError('');
    setInputFilters(
      // change the speficic inputfilters related with the filterIndex
      inputFilters.map((filter, index) => (index === inputFilters.indexOf(inputFilter)
        ? {
          ...filter,
          [field]: filterValue,
        }
        : filter)),
    );
  };

  const handleColumnChange = (event, filterIndex) => {
    // if column is date, set the value in the filter to the date in the selectedStartDate
    if (jsonColumns.find((c) => c.id === event.target.value)?.valueType === valueTypes.DATE) {
      setInputFilters(
        // change the speficic inputfilters related with the filterIndex
        inputFilters.map((filter, index) => (index === filterIndex
          ? {
            ...filter,
            column: event.target.value,
            operator: operators[0].value,
            value: selectedStartDate.format('YYYY-MM-DD HH:mm:ss'),

          }
          : filter)),

      );
    } else {
      setInputFilters(
        // change the speficic inputfilters related with the filterIndex
        inputFilters.map((filter, index) => (index === filterIndex
          ? {
            ...filter,
            column: event.target.value,
            operator: operators[0].value,
            value: '',

          }
          : filter)),
      );
    }

    // add to the columns to select a new property call selected for the columns that has been selected
    setColumnsToSelect(
      columnsToSelect.map((column) => (column.id === event.target.value
        ? { ...column, selected: true }
        : column)),
    );
  };

  const handleChangeDatePicker = (value) => {
    if (value === null) {
      setSelectedStartDate(null);
    } else {
      setSelectedStartDate(value);
      setInputFilters(
        // change the speficic inputfilters related with the filterIndex
        inputFilters.map((filter, index) => (index === inputFilters.length - 1
          ? {
            ...filter,
            value: value.format('YYYY-MM-DD HH:mm:ss'),
          }
          : filter)),
      );
    }
  };

  // the filters selected are the one that fires the useEffect
  // - update the json object (selectedfilter) when the filters change
  useEffect(() => {
    setSelectedFilters(JSON.stringify({ filter: createJsonFromFilters() }, null, 2));
  }, [filters, setSelectedFilters, createJsonFromFilters]);

  // just to initialize the filters once, when the component is mounted, with the mandatory ones
  useEffect(() => {
    const initialFilters = jsonColumns.filter((col) => col.isMandatory).map((col) => ({
      id: uuidv4(),
      column: col.id,
      operator: operators[0].value,
      value: '',
      isMandatory: col.isMandatory,
      maxValues: col.maxValues,
    }));
    if (initialFilters.length > 0) {
      setInputFilters(initialFilters);
      // deleted from the columns to select the mandatory columns
      setColumnsToSelect(
        columnsToSelect.map((column) => (column.isMandatory
          ? { ...column, selected: true }
          : column)),
      );
    }
  }, []);

  // validate mandatory filters has been added
  useEffect(() => {
    if (inputFilters.length > 0) {
      const mandatoryFilters = inputFilters.filter((filter) => filter.isMandatory);
      if (mandatoryFilters.length > 0) {
        if (mandatoryFilters.some((filter) => filter.column === '' || filter.operator === '' || filter.value === '')) {
          setFilterError('The mandatory filters must been added');
        } else {
          setFilterError('');
        }
      }
    }
    if (inputFilters.length === 0) {
      setInputFilters([
        {
          column: '', operator: '', value: '', id: uuidv4(),
        },
      ]);
    }
  }, [inputFilters, setFilterError]);

  return (
    <div className={classes.root}>

      <Card>
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            JSON Filters (
            {inputFilters?.length - 1}
            {' '}
            {inputFilters?.length - 1 === 1 ? 'filter' : 'filters'}
            {' '}
            applied)
          </Typography>
          <Grid container style={{ paddingLeft: '16px', paddingRight: '16px' }}>

            <div className={classes.tabContent}>
              {jsonColumns?.length > 0 && inputFilters?.length > 0 && (
                inputFilters.map((inputFilter, filterIndex) => (
                  <Grid
                    key={inputFilter.id}
                    item
                    container
                    direction="row"
                    spacing={4}
                  >

                    {/* // input columns */}
                    <Grid item xs={12} sm={2}>
                      <TextField
                        className={classes.filterItem}
                        key={inputFilter.id}
                        fullWidth
                        disabled={(inputFilters?.length && filterIndex !== inputFilters.length - 1) || inputFilter.isMandatory}
                        select
                        label="Column"
                        value={inputFilter.column}
                        onChange={(event) => handleColumnChange(event, filterIndex)}
                        data-testid={`input-column-${inputFilter.id}`}
                      >

                        {columnsToSelect.map((c) => (
                          (

                            // set disable the menu item if the column has been selected
                            <MenuItem key={c.id} value={c.id} data-testid={`input-column-${inputFilter.id}-${c.id}`} disabled={c.selected}>
                              {c.label}
                            </MenuItem>
                          )

                        ))}

                      </TextField>
                    </Grid>

                    {/* // input operators */}
                    <Grid item xs={12} sm={2}>
                      <TextField
                        className={classes.filterItem}
                        key={inputFilter.id}
                        disabled={inputFilters?.length && filterIndex !== inputFilters.length - 1}
                        fullWidth
                        select
                        label="Operator"
                        value={inputFilter.operator}
                        onChange={(e) => updateFilter('operator', e.target.value, inputFilter)}
                        data-testid={`input-operator-${inputFilter.id}`}
                      >
                        {
                          ![valueTypes.BOOLEAN, valueTypes.STRING].includes(jsonColumns.find((c) => c.id === inputFilter.column)?.valueType) && dateOperators.map((option) => (
                            <MenuItem key={option.value} value={option.value} data-testid={`input-operator-${option.value}-${inputFilter.id}`}>
                              {option.label}
                            </MenuItem>
                          ))
                        }

                        {
                          [valueTypes.BOOLEAN].includes(jsonColumns.find((c) => c.id === inputFilter.column)?.valueType) && booleanOperators.map((option) => (
                            <MenuItem key={option.value} value={option.value} data-testid={`input-operator-${option.value}-${inputFilter.id}`}>
                              {option.label}
                            </MenuItem>
                          ))
                        }

                        {
                          [valueTypes.STRING].includes(jsonColumns.find((c) => c.id === inputFilter.column)?.valueType) && stringOperators.map((option) => (
                            <MenuItem key={option.value} value={option.value} data-testid={`input-operator-${option.value}-${inputFilter.id}`}>
                              {option.label}
                            </MenuItem>
                          ))
                        }

                      </TextField>
                    </Grid>

                    {/* // input values */}
                    <Grid item xs={12} sm={2} md={4}>
                      {inputFilter.column
                        && jsonColumns.find((c) => c.id === inputFilter.column)?.valueType === (valueTypes.BOOLEAN || valueTypes.LIST)
                        && (
                          <TextField
                            className={classes.filterItem}
                            key={inputFilter.id}
                            disabled={inputFilters?.length && filterIndex !== inputFilters.length - 1}
                            select
                            label="Value"
                            fullWidth
                            value={inputFilter.value}
                            onChange={(e) => updateFilter('value', e.target.value, inputFilter)}
                            data-testid={`input-value-boolean-list-${inputFilter.id}`}
                          >
                            {jsonColumns.find((c) => c.id === inputFilter.column)?.values?.map((option) => (
                              <MenuItem key={option} value={option} data-testid={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      {inputFilter.column
                        && jsonColumns.find((c) => c.id === inputFilter.column)?.valueType === valueTypes.STRING
                        && (
                          <TextField
                            className={classes.filterItem}
                            key={inputFilter.id}
                            disabled={inputFilters?.length && filterIndex !== inputFilters.length - 1}
                            fullWidth
                            label={inputFilter.operator === 'IN' ? 'Values (separated by commas)' : 'Value'}
                            value={inputFilter.value}
                            onChange={(e) => updateFilter('value', e.target.value, inputFilter)}
                            data-testid={`input-value-string-${inputFilter.id}`}
                          />
                        )}
                      {inputFilter.column
                        && jsonColumns.find((c) => c.id === inputFilter.column)?.valueType === valueTypes.NUMBER
                        && (
                          <TextField
                            className={classes.filterItem}
                            key={inputFilter.id}
                            disabled={inputFilters?.length && filterIndex !== inputFilters.length - 1}
                            fullWidth
                            label={inputFilter.operator === 'IN' ? 'Values (enter the values separated by commas)' : 'Value'}
                            inputProps={{
                              pattern: '\\d*',
                            }}
                            value={inputFilter.value}
                            onChange={(e) => updateFilter('value', e.target.value, inputFilter)}
                            data-testid={`input-value-number-${inputFilter.id}`}
                          />
                        )}
                      {
                        inputFilter.column
                        && jsonColumns.find((c) => c.id === inputFilter.column)?.valueType === valueTypes.DATE
                        && (

                          <Grid item className={classes.dateGrid} container>
                            <MuiPickersUtilsProvider utils={DateUtils}>
                              <KeyboardDatePicker
                                autoOk
                                id={`input${id}Date`}
                                key={inputFilter.id}
                                disabled={inputFilters?.length && filterIndex !== inputFilters.length - 1}
                                variant="outlined"
                                orientation="landscape"
                                value={selectedStartDate}
                                format="yyyy-MM-DD HH:mm:ss"
                                onChange={handleChangeDatePicker}
                                color="secondary"
                                data-testid={`input-value-calendar-${inputFilter.id}`}
                                TextFieldComponent={({ value, onChange, onClick }) => (
                                  <TextField
                                    value={value}
                                    data-testid={`input-value-calendar-${inputFilter.id}`}
                                    disabled={inputFilters?.length && filterIndex !== inputFilters.length - 1}
                                    onChange={onChange}
                                    variant="outlined"
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton onClick={onClick}>
                                            <CalendarIcon />
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}

                                  />
                                )}
                              />
                            </MuiPickersUtilsProvider>
                          </Grid>

                        )
                      }
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} direction="row">
                      <Grid contaicner alignItems="center" justifyContent="center">
                        {/* show this only in the last element of the array */}
                        {filterIndex === inputFilters.length - 1 && (
                          <Button variant="contained" color="secondary" onClick={() => addFilter(inputFilter)} startIcon={<AddIcon />} data-testid="add-filter-button" disabled={selectorError !== ''} className={classes.filterButton}> ADD </Button>)}

                        {/* show this in all elements except in the last one */}
                        {filterIndex !== inputFilters.length - 1 && (
                          <Grid container justifyContent="center" alignContent="center" alignItems="center" display="flex">
                            <Grid item className={classes.andItem}>
                              AND
                            </Grid>
                            <Grid item>
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => deleteFilter(inputFilter)}
                                startIcon={<DeleteIcon />}
                                data-testid="delete-filter-button"
                                className={classes.filterButton}
                              >
                                {' '}
                                DELETE
                              </Button>
                            </Grid>

                          </Grid>
                        )}
                      </Grid>
                    </Grid>

                  </Grid>
                ))
              )}
            </div>

          </Grid>
        </CardContent>
      </Card>

    </div>
  );
}

JSONBuilder.propTypes = {
  setSelectedFilters: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  jsonColumns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      valueType: PropTypes.string,
      values: PropTypes.arrayOf(PropTypes.string),
      isMandatory: PropTypes.bool,
      maxValues: PropTypes.number,
    }),
  ).isRequired,
  setFilterError: PropTypes.func,
};

JSONBuilder.defaultProps = {
  setFilterError: () => { },
};

export default JSONBuilder;
