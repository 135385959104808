export const authUri = 'auth.portal.dev.niotp.com';
export const redirectUri = 'https://portal.dev.niotp.com/';
export const version = '3.5.0';
export const nespressoDashboardUrl = 'https://app.powerbi.com/links/9T8rsfxax1?ctid=12a3af23-a769-4654-847f-958f3d479f4a&pbi_source=linkShare';
export const ndgDashboardUrl = 'https://app.powerbi.com/links/XQ3_1OJDd5?ctid=12a3af23-a769-4654-847f-958f3d479f4a&pbi_source=linkShare';
export const nescafeDashboardUrl = 'https://app.powerbi.com/links/S07VMe4KSi?ctid=12a3af23-a769-4654-847f-958f3d479f4a&pbi_source=linkShare';
export const newDashboardUrl = 'https://nestle.service-now.com/sp?id=sc_cat_item&sys_id=2061ac43dbbbbb800f71f9851d96192f';

// Support screen URLs
export const certificateUrl = 'https://nestle.service-now.com/sp?id=sc_cat_item&sys_id=2eeefc3adb661cdcb7ccf572f3961921';
export const nesspresoB2BUrl = 'https://nestle.service-now.com/sp?id=sc_cat_item&sys_id=ff90dd98dba394140a8a18fe3b96199c';
export const firmwareUrl = 'https://nestle.service-now.com/sp?id=sc_cat_item&sys_id=64409c9edb0b08101286f1571d961901';
export const assetDeploUrl = 'https://nestle.service-now.com/sp?id=sc_cat_item&sys_id=a460648bdb7bbb800f71f9851d9619e5';
export const apiUrl = 'https://nestle.service-now.com/sp?id=sc_cat_item&sys_id=cd51aacd874c4118e909766acebb355b';
export const deviceEnviromentUrl = 'https://nestle.service-now.com/sp?id=sc_cat_item&sys_id=7643df971bea1d50fb27ea83b24bcbe8&sysparm_domain_restore=false&sysparm_stack=no';
export const dataSaringUrl = 'https://nestle.service-now.com/sp?id=sc_cat_item&sys_id=c887d3df1b6e1d50fb27ea83b24bcba9&sysparm_domain_restore=false&sysparm_stack=no';

// Suppport screen URLs for request
export const costumerPlatformUrl = 'https://nestle.service-now.com/sp?id=sc_cat_item&sys_id=70217c3b1b4ee15430fdfc49cd4bcbe2&sysparm_domain_restore=false&sysparm_stack=no';
export const nesspresoPortalUrl = 'https://nestle.service-now.com/sp?id=sc_cat_item&sys_id=a65aba74db2b4c5099baf572f3961928';
export const apiCloud2CloudUrl = 'https://nestle.service-now.com/sp?id=sc_cat_item&sys_id=77ffc6e1877edd10c04bb9d09bbb358d&sysparm_domain_restore=false&sysparm_stack=no';

// Useful links
export const authLogs = 'https://eu-west-1.console.aws.amazon.com/cloudwatch/home?region=eu-west-1#logsV2:log-groups/log-group/$252Faws$252Flambda$252FALMAuth-DEV-1-0-13-b1-1/log-events';
export const backendLogs = 'https://eu-west-1.console.aws.amazon.com/cloudwatch/home?region=eu-west-1#logsV2:log-groups/log-group/$252Faws$252Flambda$252FAlm-DEV-1-0-13-b1-1/log-events';
export const ebLogs = 'https://eu-west-1.console.aws.amazon.com/cloudwatch/home?region=eu-west-1#logsV2:log-groups/log-group/$252Faws$252Flambda$252FAlmEB-DEV-1-0-13-b1-1/log-events';
export const releases = 'https://nestle.atlassian.net/wiki/spaces/DIIS/pages/107990751/Portal+Releases';
export const changelog = 'https://dsu-bitbucket.nestle.biz/projects/DNICP/repos/iot-portal/browse/alm-react-frontend/CHANGELOG.md';
export const businessDocumentation = 'https://nestle.atlassian.net/wiki/spaces/DIIS/pages/107919379/Portal';
export const roadMap = 'https://nestle.atlassian.net/jira/plans/733/scenarios/736/summary';
export const backlog = 'https://nestle.atlassian.net/jira/software/c/projects/DII/boards/4386/backlog';
export const cognito = 'https://eu-west-1.console.aws.amazon.com/cognito/v2/idp/user-pools/eu-west-1_Y7gEBL6oW/users?region=eu-west-1';
