const changelog = `# Updates
All notable changes to this project will be documented in this page.

Stay updated with every version's enhancements and optimizations to ensure you're making the most out of our application.

The format is based on [Keep a Changelog](https://keepachangelog.com/en/1.0.0/),
and this project adheres to [Semantic Versioning](https://semver.org/spec/v2.0.0.html).

## v3.6.0
**New**

- **SSO Production:**  Enables user authentication across Nestle single sign-on system. Users no longer need to provide their email for login. However, you should request permission for access upon first use.

## v3.5.0

**New**
- **Bundles Creation:**  through this new feature users can now create bundles, which are groups of assets that can be deployed all at once.

## v3.4.0

### Deployment Kebab Menu for Deleted Deployed Items
- A new kebab menu has been added for deleted deployed items. This allows users to easily manage and interact with deleted deployments through a streamlined and intuitive interface.

### New UI for the Left Bar Menu
- The Left Bar Menu has undergone a complete redesign to enhance user experience. The new UI is more intuitive, providing easier navigation and sections.

### Multi Selector Types in Assets and Deployments
- Multi Selector types have been added to both assets and deployments. This feature allows users to filter by multiple file types items at once.

### Added SSO (Single Sign-On) for Production Environment
- Single Sign-On (SSO) has been integrated into the production environment. This enhancement simplifies the login process, allowing users to access the system with their existing credentials.

### Website Domain Migration
- The website has been migrated to our new domain. All references and links have been updated from the old domain (alm.niotp) to the new domain (portal.niotp). This ensures continuity and improved access to our services.

### Website Domain Migration
- The website has been migrated to our new domain. 

### Updated Links to New Confluence Website
- All references and hyperlinks previously pointing to our old Confluence site have been updated to direct users to the new Confluence website. This update ensures that all documentation remains accessible and that workflow disruptions are minimized.


## v3.3.0

**New**

- **Notification Center:** through this new feature users are now notified when CSV export is ready or a new version of the portal is released.

**Fixed**

- **CSV Export:** Now allows exporting all lines returned by the search, removing the previous limit of 1000 rows. Users can now get the complete information in a single export.

**Updated**

- **Assets Deployments:** Replaced Actor ID with the email address to improve clarity and usability.
- **Asset Deployment Enhancements:** Added an exclusion field for new deployments, allowing users to exclude device serials and markets in global deployments.


## v3.2.0

### Fixed

**New Deployment**

Asset version appears as undefined and project disappears from dropdown menu.

### Updated

**Updated button labels to clearly describe the actions they perform**
Updated button labels to clearly describe the actions they perform.

### Updated

**Enhanced "Create Deployment" Page**

- Added a text box for entering device serial lists separated by commas, alongside the option to upload serial lists in CSV/XLSX format.
- Improved the overall user interface.

### Updated

**Enhanced Asset Submission Process**

After adding an asset package and clicking SUBMIT on the Assets page, a pop-up message is displayed:
  - If the user clicks NO, the assets page with the assets list is displayed.
  - If the user clicks YES, they are directed to the "Create Deployment" page.

`;

export default changelog;
