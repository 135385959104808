/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { useState } from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useQuery, useMutation } from '@tanstack/react-query';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Grid, LinearProgress, makeStyles, Button, Typography, List, ListItem, withStyles, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField,
} from '@material-ui/core';
import * as XLSX from 'xlsx';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'; import SubjectIcon from '@material-ui/icons/Subject';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import AddAlertIcon from '@material-ui/icons/AddAlert';
import SendIcon from '@material-ui/icons/Send';
import CancelScheduleSendIcon from '@material-ui/icons/CancelScheduleSend';
import MessageCard from '../../components/utils/MessageCard';
import PageBanner from '../../components/utils/PageBanner';
import GraphqlService from '../../service/graphqlService';
import ProgressDialog from '../../components/utils/ProgressDialog';
import { useSnackbar } from '../../providers/SnackbarContext';

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.background.typography,
    padding: 16,
  },
  button: {
    float: 'right',
    margin: '0 0 16px 24px',
  },
  tableButton: {
    height: 48,
    justifyContent: 'center',
    margin: 4,
    color: 'white',
    borderRadius: 12,
  },
  tableIcons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingBottom: 16,
    flexWrap: 'wrap',
  },
  mainTable: {
    borderRadius: 12,
    boxShadow: theme.customShadows.noTopShadow,
  },
  notificationTitle: {
    flexGrow: 1,
  },
  closeNotificationsButton: {
    float: 'right',
  },
  titleNotifications: {
    padding: 0,
  },
}));

const StyledTableHeaderCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    width: '9px',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.form,
    },
  },
}))(TableRow);

const getCognitoUsersGroupsCSV = async () => GraphqlService.getCognitoUsersGroupsCSV();

export default function CognitoUsersList() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationDescription, setNotificationDescription] = useState('');
  const openSnackBar = useSnackbar();
  const {
    data: csvData,
    error: errorFetching,
    isLoading,
  } = useQuery(['cognitoUsers'], getCognitoUsersGroupsCSV, {
    cacheTime: 300000, // Cache for 5 minutes
    staleTime: 300000, // Data is considered stale after 5 minutes
    refetchOnWindowFocus: false, // Disable refetching on window focus
    refetchOnReconnect: false, // Disable refetching on reconnect
    refetchInterval: false, // Disable polling
    select: (res) => {
      const rows = res.split('\n').slice(1).map((row) => {
        if (!row) {
          return null;
        }
        const [user, createdDate, email, groups] = row.split(',');
        if (!user || !email) {
          return null;
        }
        const parsedGroups = groups && groups.split(';').map((group) => {
          const parts = group && group.trim().split(':');
          return `${parts[0]}: ${parts[1]}`;
        }).join(', ');
        return {
          user, createdDate, email, groups: parsedGroups,
        };
      });
      return { rows, file: res };
    },
  });

  const createNotificationMutation = useMutation((variables) => GraphqlService.createNotification(variables.email, variables.notificationTitle, variables.notificationDescription));

  const getAllUsers = () => {
    const emails = csvData.rows?.filter((row) => row?.email);
    return emails.map((row) => row.email).join(';');
  };

  const sendNotifications = async () => {
    try {
      const users = csvData.rows?.filter((row) => row?.email);
      const promiseNotifications = users.map((user) => (createNotificationMutation.mutateAsync({ email: user.email.trim(), notificationTitle, notificationDescription })));
      await Promise.all(promiseNotifications);
      // clean fields
      setNotificationTitle('');
      setNotificationDescription('');
      openSnackBar('Notifications sent successfully', 'success');
      setOpen(false);
    } catch (error) {
      openSnackBar(error.message, 'error');
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setNotificationTitle('');
    setNotificationDescription('');
    setOpen(false);
  };

  const downloadCsv = () => {
    const blob = new Blob([csvData.file], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'CognitoUsersList.csv');
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const openUserActivity = (userId) => {
    const url = `${window.location.origin}/#/userActivity/${userId}`;
    window.open(url, '_blank');
  };

  const downloadXls = () => {
    if (!csvData?.rows) {
      return;
    }
    const validData = csvData.rows.filter((row) => row !== null);
    const worksheet = XLSX.utils.json_to_sheet(validData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');

    const xlsBuffer = XLSX.write(workbook, { bookType: 'xls', type: 'array' });
    const blob = new Blob([xlsBuffer], { type: 'application/vnd.ms-excel' });

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'CognitoUsersList.xls');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const copyUsersInClipboard = () => {
    const users = getAllUsers();
    navigator.clipboard.writeText(users);
  };

  function renderContent() {
    if (errorFetching) {
      return <MessageCard message={`Error: ${errorFetching.message}`} />;
    }

    if (isLoading) {
      return (
        <>
          <ProgressDialog open header="Retrieving cognito users, please wait" />
          <LinearProgress id="linear-progress-id" style={{ width: '100%' }} />
        </>
      );
    }

    if (csvData?.rows?.length > 0) {
      return (
        <>
          <Grid container direction="column" spacing={2}>
            <div className={classes.tableIcons}>
              <Button id="btnCSV" type="form" startIcon={<GetAppIcon />} className={classes.tableButton} color="secondary" variant="contained" onClick={downloadCsv}> CSV </Button>
              <Button id="btnXLS" type="form" startIcon={<SubjectIcon />} className={classes.tableButton} color="secondary" variant="contained" onClick={downloadXls}> XLS </Button>
              <Button id="btnSendEmail" type="form" startIcon={<AssignmentTurnedInIcon />} className={classes.tableButton} color="secondary" variant="contained" onClick={copyUsersInClipboard}> CLIPBOARD EMAILS </Button>
              <Button id="btnSendAlertIcon" type="form" startIcon={<AddAlertIcon />} className={classes.tableButton} color="secondary" variant="contained" onClick={handleOpen}> SEND NOTIFICATIONS </Button>
            </div>

            <TableContainer component={Paper} className={classes.mainTable}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableHeaderCell className={classes.tableHead}>User ID</StyledTableHeaderCell>
                    <StyledTableHeaderCell className={classes.tableHead}>Creation Date</StyledTableHeaderCell>
                    <StyledTableHeaderCell className={classes.tableHead}>Email</StyledTableHeaderCell>
                    <StyledTableHeaderCell className={classes.tableHead}>Groups</StyledTableHeaderCell>
                    <StyledTableHeaderCell className={classes.tableHead}>Activity</StyledTableHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {csvData.rows.map((row) => (row && (
                    <StyledTableRow key={row.user}>
                      <StyledTableCell>{row.user}</StyledTableCell>
                      <StyledTableCell>{row.createdDate}</StyledTableCell>
                      <StyledTableCell>{row.email}</StyledTableCell>
                      <StyledTableCell>
                        <Typography variant="body2">
                          <List dense>
                            {row?.groups && row.groups.split(', ').map((group) => (
                              <ListItem key={group}>{group.includes('rootAccess') ? 'rootAccess' : group}</ListItem>
                            ))}
                          </List>
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <IconButton onClick={() => openUserActivity(row.user)}>
                          <DirectionsRunIcon />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  )))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </>
      );
    }

    return <MessageCard message="No data available" />;
  }

  return (
    <Box>
      <PageBanner title="COGNITO USERS" />
      <Grid className={classes.background} container spacing={0} direction="column" alignItems="stretch">
        <div className={classes.separate}>
          {renderContent()}
        </div>
      </Grid>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle className={classes.titleNotifications}>
          <PageBanner title="SEND NOTIFICATIONS" />
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Notification Title"
            fullWidth
            value={notificationTitle}
            onChange={(e) => setNotificationTitle(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Notification Description"
            fullWidth
            multiline
            rows={4}
            value={notificationDescription}
            onChange={(e) => setNotificationDescription(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button id="btnCancelNotifications" type="form" startIcon={<CancelScheduleSendIcon />} className={classes.tableButton} color="secondary" variant="contained" onClick={handleClose}> Cancel </Button>

          <Button id="btnSendNotifications" type="form" startIcon={<SendIcon />} className={classes.tableButton} color="secondary" variant="contained" onClick={sendNotifications}> Send </Button>

        </DialogActions>
      </Dialog>
    </Box>
  );
}
