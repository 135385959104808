/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  IconButton, Menu, MenuItem, makeStyles,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  customAlert: {
    padding: 20,
  },
  menuItem: {
    minWidth: 172,
    minHeight: 40,
    /* make blue on hover item and text dark blue */
    '&:hover': {
      backgroundColor: theme.palette.secondary.subMain,
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium,
    },
    fontFamily: theme.typography.h3.fontFamily,
    fontSize: '14px',
    letterSpacing: '0',
    lineHeight: '16px',
  },
}));

export default function KebabMenu({ items, deployment }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const history = useHistory();
  const match = useRouteMatch();

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClickMenuItem = (event, item) => {
    event.stopPropagation();
    history.push(`${item.optionURL}/${deployment.deploymentID}`);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        size="small"
        sx={{ ml: 2 }}
        aria-controls={anchorEl ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={anchorEl ? 'true' : undefined}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {items.map((item) => (
          <MenuItem
            id={`btn${item.optionName}`}
            key={item.optionName}
            onClick={(evt) => handleClickMenuItem(evt, item)}
            className={classes.menuItem}
          >
            {item.optionName}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

KebabMenu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  deployment: PropTypes.objectOf(PropTypes.any).isRequired,
};
