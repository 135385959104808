/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import moment from 'moment';

export function formatToPrintableDate(rawDate) {
  const date = new Date(rawDate);
  return moment(date).format('DD/MM/yyyy HH:mm:ss');
}

export function formatToPrintableDateWithoutTime(rawDate) {
  const date = new Date(rawDate);
  return moment(date).format('DD/MM/yyyy');
}

function datesValid(dateStart, dateEnd, timeStart, timeEnd, setGreaterSmallerDateError, setMoreThanMaxDaysDateError, maxLength) {
  let validation = true;

  const dateStartAux = moment(`${dateStart.format('YYYY-MM-DD')} ${timeStart}`, 'YYYY-MM-DD HH:mm');
  const dateEndAux = moment(`${dateEnd.format('YYYY-MM-DD')} ${timeEnd}`, 'YYYY-MM-DD HH:mm');

  if (dateStartAux > dateEndAux) {
    setGreaterSmallerDateError(true);
    validation = false;
  }

  if (moment(dateEnd).diff(moment(dateStart), 'days', true) > maxLength) {
    // validate max days lenght
    setMoreThanMaxDaysDateError(true);
    validation = false;
  }

  return validation;
}

export function validate(dateStart, dateEnd, timeStart, timeEnd, maxLength, setDateStartError, setDateEndError, setSelectedStartDate, setSelectedStartTime, setSelectedEndDate, setSelectedEndTime, setGreaterSmallerDateError, setMoreThanMaxDaysDateError) {
  if (dateStart && dateEnd && timeStart && timeEnd) {
    let validates = true;
    if (!dateStart.isValid()) {
      setDateStartError(true);
      validates = false;
    } else if (!dateEnd.isValid()) {
      setDateEndError(true);
      validates = false;
    } else if (!datesValid(dateStart, dateEnd, timeStart, timeEnd, setGreaterSmallerDateError, setMoreThanMaxDaysDateError, maxLength)) {
      validates = false;
    }
    return validates;
  }
  setSelectedStartDate(null);
  setSelectedStartTime('');
  setSelectedEndDate(null);
  setSelectedEndTime('');
  return true;
}

export function calculateStartDateTimeIso(startDate, startTime) {
  if (startDate && startTime) {
    const startDateTime = moment(`${startDate.format('YYYY-MM-DD')} ${startTime}`, 'YYYY-MM-DD HH:mm');
    const startOffset = startDateTime.utcOffset();
    const startDateTimeIso = startDateTime.utcOffset(0).add(startOffset, 'minutes').toISOString(true);
    return startDateTimeIso;
  }
  const startDateTime = moment().add(-29, 'days');
  const startOffset = startDateTime.utcOffset();
  const startDateTimeIso = startDateTime.utcOffset(0).add(startOffset, 'minutes').toISOString(true);
  return startDateTimeIso;
}

export function calculateEndDateTimeIso(endDate, endTime) {
  if (endDate && endTime) {
    const endDateTime = moment(`${endDate.format('YYYY-MM-DD')} ${endTime}`, 'YYYY-MM-DD HH:mm');
    const endOffset = endDateTime.utcOffset();
    const endDateTimeIso = endDateTime.utcOffset(0).add(endOffset, 'minutes').toISOString(true);
    return endDateTimeIso;
  }
  const endDateTime = moment();
  const endOffset = endDateTime.utcOffset();
  const endDateTimeIso = endDateTime.utcOffset(0).add(endOffset, 'minutes').toISOString(true);
  return endDateTimeIso;
}
