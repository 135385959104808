import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Checkbox,
  makeStyles, Typography, useTheme, Card, CardHeader, IconButton, ListItem, ListItemText, CardContent,
} from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';

import PropTypes from 'prop-types';
import {
  React, useContext, useState,
} from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useMutation } from '@tanstack/react-query';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DescriptionIcon from '@material-ui/icons/Description';
import LayersIcon from '@material-ui/icons/Layers';
import CodeIcon from '@material-ui/icons/Code';
import MemoryIcon from '@material-ui/icons/Memory';
import CategoryIcon from '@material-ui/icons/Category';
import InfoIcon from '@material-ui/icons/Info';
import AssignmentIcon from '@material-ui/icons/Assignment';
import GraphqlService from '../../service/graphqlService';
import { formatToPrintableDate } from '../../utils/dateUtils';
import SelectedAssetsContext from '../../config/SelectedAssetsContext';
import Restricted from '../../authorization/Restricted';
import {
  CREATE_NON_GLOBAL_DEPLOY_PERMISSION,
} from '../../utils/constants';
import EditableInput from '../EditableInput';
import { useProject } from '../../config/ProjectContext';
import { useSnackbar } from '../../providers/SnackbarContext';

export default function AssetAccordion({
  asset: initialAsset, id, expandedProp,
}) {
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: 0,
      marginBottom: 4,
      border: `thin solid ${theme.palette.background.form}`,
      color: theme.palette.primary.text,
      backgroundColor: 'white',
    },
    headingTitle: {
      fontSize: 16,
    },
    headingDate: {
      fontSize: 14,
    },
    details: {
      borderTop: `3px solid ${theme.palette.background.paper}`,
      lineHeight: '90px',
      backgroundColor: alpha(theme.palette.background.paper, 0.1),
    },
    detailsSelected: {
      lineHeight: '9f0px',
      padding: 24,
      backgroundColor: alpha(theme.palette.primary.banner, 0.1),
    },
    detailsTitle: {
      fontSize: 14,
      paddingRight: '8px',
    },
    key: {
      color: theme.palette.primary.subMain,
    },
    cardContent: {
      backgroundColor: 'white',
      boxShadow: theme.customShadows.regularShadow,
      color: theme.palette.primary.text,
      height: '100%',
      flexGrow: 1,
      borderRadius: 12,
    },
    summaryExpanded: {
      backgroundColor: alpha(theme.palette.background.paper, 0.3),
      wordBreak: 'break-word',
    },
    summaryCollapsed: {
      backgroundColor: theme.palette.background.paper,
      wordBreak: 'break-word',
    },
    cardTitle: {
      fontSize: 16,
      whiteSpace: 'normal',
      wordBreak: 'break-word',
    },
    selectedAsset: {
      backgroundColor: alpha(theme.palette.primary.banner, 0.3),
    },
    cardHeader: {
      padding: 4,
    },
    cardTopHeader: {
      padding: 0,
      paddingLeft: 2,
      marginBottom: -28,
    },
  }));

  const classes = useStyles();
  const theme = useTheme();
  const updateAssetMutation = useMutation((variables) => GraphqlService.updateAssetDescription(variables.projectId, variables.fileId, variables.assetDescription));
  const openSnackBar = useSnackbar();
  const [expanded, setExpanded] = useState(expandedProp || false);
  const { selectedAssets, setSelectedAssets } = useContext(SelectedAssetsContext);
  const { project } = useProject();
  const [asset, setAsset] = useState(initialAsset);

  function handleClickCheckbox(e) {
    e.stopPropagation();
    const selectedAsset = JSON.parse(e.target.value);

    if (e.target.checked) {
      setSelectedAssets([...selectedAssets, selectedAsset]);
    } else {
      setSelectedAssets(selectedAssets.filter((item) => item.fileID !== selectedAsset.fileID));
    }
  }

  async function handleDescriptionChange(description) {
    return updateAssetMutation.mutateAsync({
      projectId: project.code,
      fileId: asset.fileID,
      assetDescription: description,
    });
  }

  const handleInputChange = (description) => {
    handleDescriptionChange(JSON.stringify(description))
      .then((res) => {
        console.log('0res', res);
        setAsset({
          ...asset,
          assetDescription: JSON.parse(res),
        });
        openSnackBar('Asset description updated successfully!', 'success');
      })
      .catch((err) => {
        openSnackBar(`An error occurred while updating the asset description. Error: ${err} `, 'error');
      });
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      openSnackBar(`Value ${text} copied successfully to the clipboad!`, 'success');
    }).catch((err) => {
      openSnackBar('Failed to copy text to clipboard', err);
    });
  };
  const isSelected = selectedAssets.some((selectedAsset) => selectedAsset.fileID === asset.fileID);
  const determineAcordionSummaryClassName = () => {
    if (isSelected) {
      return classes.selectedAsset;
    }
    if (expanded) {
      return classes.summaryExpanded;
    }
    return classes.summaryCollapsed;
  };

  return (
    <Accordion
      id={`asset-accordion-detail-${id}`}
      expanded={expanded}
      className={classes.root}
      TransitionProps={{ unmountOnExit: true }}
      onChange={() => setExpanded(!expanded)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon id={`${id}btnExpand`} style={{ color: theme.palette.primary.text }} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={determineAcordionSummaryClassName()}
      >
        <Grid
          style={{ margin: -18 }}
          container
          spacing={0}
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
        >
          <Grid item xs={1} sm={1}>
            <Restricted
              to={[CREATE_NON_GLOBAL_DEPLOY_PERMISSION]}
            >
              <Checkbox
                value={JSON.stringify(asset)}
                onClick={(e) => handleClickCheckbox(e)}
              />
            </Restricted>
          </Grid>
          <Grid item xs={5} sm={3}>
            <ListItem>
              <ListItemText
                id={`${id}Type`}
                primary={
                  <Typography variant="caption" color="textSecondary">Type</Typography>
                }
                secondary={<Typography variant="body1">{asset.type}</Typography>}
              />
            </ListItem>
          </Grid>

          <Grid item xs={6} sm={3}>
            <ListItem>
              <ListItemText
                id={`${id}Version`}
                primary={
                  <Typography variant="caption" color="textSecondary">Version</Typography> // Smaller, gray text
                }
                secondary={<Typography variant="body1">{!asset.assetDescription?.Version || asset.assetDescription?.Version === null ? 'Not available' : `v.${asset.assetDescription.Version}`}</Typography>}
              />
            </ListItem>
          </Grid>

          <Grid item xs={12} sm={5}>
            <ListItem>
              <ListItemText
                id={`${id}Date`}
                primary={
                  <Typography variant="caption" color="textSecondary">Created Date</Typography>
                }
                secondary={<Typography variant="body1">{formatToPrintableDate(asset.dateTime)}</Typography>}
              />
            </ListItem>
          </Grid>
        </Grid>
      </AccordionSummary>
      {/** ********* Details ********** */}
      <AccordionDetails className={isSelected ? classes.detailsSelected : classes.details}>
        <Grid container spacing={1} alignItems="stretch">

          <Grid item xs={12} md={12}>
            <Card className={classes.cardContent}>
              <CardHeader
                className={classes.cardHeader}
                avatar={(
                  <DescriptionIcon />
                )}
                action={asset.fileID && (
                  <IconButton onClick={() => copyToClipboard(asset.fileID)}>
                    <FileCopyIcon />
                  </IconButton>
                )}
                title={<div className={classes.cardTitle}>File ID</div>}
                subheader={<div className={classes.cardTitle} id={`${id}File`}>{asset.fileID}</div>}
              />

            </Card>
          </Grid>

          {/* Chunks */}
          <Grid item xs={12} md={12}>
            <Card className={classes.cardContent}>
              <CardHeader
                className={classes.cardHeader}
                avatar={<LayersIcon />}
                action={Array.isArray(asset.chunks) && (
                  <IconButton onClick={() => copyToClipboard(asset.chunks.map((path) => path.split('/').pop()).join(', '))}>
                    <FileCopyIcon />
                  </IconButton>
                )}
                title={<div className={classes.cardTitle}>Chunks</div>}
                subheader={<div className={classes.cardTitle} id={`${id}Chunks`}>{Array.isArray(asset.chunks) ? asset.chunks.map((path) => path.split('/').pop()).join(', ') : 'No info for chunks'}</div>}
              />
            </Card>
          </Grid>

          <Grid item xs={12} md={12}>
            <Card className={classes.cardContent}>
              <CardHeader
                className={classes.cardHeader}
                avatar={(
                  <AssignmentIcon />
                )}
                title={<div className={classes.cardTitle}>Manufacturer signature</div>}
                subheader={<div className={classes.cardTitle} id={`${id}Ms`}>{asset.manufacturerSignature || 'There is no value for the manufacturer signature'}</div>}
              />

            </Card>
          </Grid>

          {/* Software requirements */}
          <Grid item xs={12} md={12}>
            <Card className={classes.cardContent}>
              <CardHeader
                className={classes.cardHeader}
                avatar={<CodeIcon />}
                title={<div className={classes.cardTitle}>Software Requirements</div>}
                subheader={<div className={classes.cardTitle} id={`${id}Sw`}>{asset.softwareRequirements || 'No info for software requirements'}</div>}
              />
            </Card>
          </Grid>

          {/* Hardware requirements */}
          <Grid item xs={12} md={12}>
            <Card className={classes.cardContent}>
              <CardHeader
                className={classes.cardHeader}
                avatar={<MemoryIcon />}
                title={<div className={classes.cardTitle}>Hardware Requirements</div>}
                subheader={<div className={classes.cardTitle} id={`${id}Hw`}>{asset.hardwareRequirements || 'No info for hardware requirements'}</div>}
              />
            </Card>
          </Grid>

          {/* Type requirements */}
          {asset.typeRequirements && asset.typeRequirements.length > 0 && (
            <Grid item xs={12} md={12}>
              <Card className={classes.cardContent}>
                <CardHeader
                  className={classes.cardHeader}
                  avatar={<CategoryIcon />}
                  title={<div className={classes.cardTitle}>Type Requirements</div>}
                  subheader="Type requirements for the asset."
                />
                <CardContent>
                  <Grid container spacing={1} alignItems="stretch">
                    <Grid item xs={12}>
                      <Card className={classes.cardContent}>
                        <CardHeader
                          className={classes.cardHeader}
                          title={<div className={classes.cardTitle}>Type</div>}
                          subheader={<div className={classes.cardTitle} id={`${id}Tr`}>{asset.typeRequirements[0].type || 'No info for type'}</div>}
                        />

                      </Card>
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <Card className={classes.cardContent}>
                        <CardHeader
                          className={classes.cardHeader}
                          title={<div className={classes.cardTitle}>Software requirements</div>}
                          subheader={<div className={classes.cardTitle} id={`${id}TrSw`}>{asset.typeRequirements[0].softwareRequirements || 'No info for software requirements'}</div>}
                        />

                      </Card>
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <Card className={classes.cardContent}>
                        <CardHeader
                          className={classes.cardHeader}
                          title={<div className={classes.cardTitle}>Hardware requirements</div>}
                          subheader={<div className={classes.cardTitle} id={`${id}TrHw`}>{asset.typeRequirements[0].hardwareRequirements || 'No info for hardware requirements'}</div>}
                        />

                      </Card>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}

          {/* Metadata */}
          {(asset.metaData.major || asset.metaData.minor || asset.metaData.build || asset.metaData.hash) && (
            <Grid item xs={12} md={12}>
              <Card className={classes.cardContent}>

                <CardHeader
                  className={classes.cardHeader}
                  avatar={<InfoIcon />}
                  title={<div className={classes.cardTitle}>Metadata</div>}
                  subheader="Metadata for the asset."
                />
                <CardContent>

                  <Grid container spacing={1} alignItems="stretch">
                    <Grid item xs={6} md={4}>
                      <Card className={classes.cardContent}>
                        <CardHeader
                          className={classes.cardHeader}
                          title={<div className={classes.cardTitle}>Major</div>}
                          subheader={<div className={classes.cardTitle} id={`${id}Major`}>{asset.metaData.major || 'No info for major version'}</div>}
                        />

                      </Card>
                    </Grid>

                    <Grid item xs={6} md={4}>
                      <Card className={classes.cardContent}>
                        <CardHeader
                          className={classes.cardHeader}
                          title={<div className={classes.cardTitle}>Minor</div>}
                          subheader={<div className={classes.cardTitle} id={`${id}Minor`}>{asset.metaData.minor || 'No info for minor version'}</div>}
                        />

                      </Card>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <Card className={classes.cardContent}>
                        <CardHeader
                          className={classes.cardHeader}
                          title={<div className={classes.cardTitle}>Build</div>}
                          subheader={<div className={classes.cardTitle} id={`${id}Build`}>{asset.metaData.build || 'No info for build'}</div>}
                        />

                      </Card>
                    </Grid>

                    <Grid item xs={12}>
                      <Card className={classes.cardContent}>
                        <CardHeader
                          className={classes.cardHeader}
                          title={<div className={classes.cardTitle}>Hash</div>}
                          subheader={<div className={classes.cardTitle} id={`${id}Hash`}>{asset.metaData.hash || 'No info for hash'}</div>}
                        />

                      </Card>
                    </Grid>

                    <Grid item xs={12}>
                      <Card className={classes.cardContent}>
                        <CardHeader
                          className={classes.cardHeader}
                          title={<div className={classes.cardTitle}>Raw</div>}
                          subheader={<div className={classes.cardTitle} id={`${id}Raw`}>{asset.metaData.raw || 'No info for raw'}</div>}
                        />

                      </Card>
                    </Grid>

                  </Grid>

                </CardContent>

              </Card>
            </Grid>
          )}

          {asset.assetDescription && (
            <Grid item xs={12} md={12}>
              <Card className={classes.cardContent}>
                <CardHeader
                  className={classes.cardTopHeader}
                  avatar={<DescriptionIcon />}
                  title={<div className={classes.cardTitle}>Asset Description</div>}
                  subheader="Detailed descriptions for the asset."
                />
                <CardContent>
                  <Grid container spacing={1} alignItems="stretch">
                    {Object.entries(asset.assetDescription).map(([key, value]) => (
                      <Grid item xs={12} md={6} key={key}>
                        <Card className={classes.cardContent}>
                          <CardHeader
                            className={classes.cardHeader}
                            title={<div className={classes.cardTitle}>{key}</div>}
                            subheader={(
                              <EditableInput
                                value={value || '-'}
                                onChange={(change) => handleInputChange({ ...asset.assetDescription, [key]: change })}
                              />
                            )}
                          />
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>

      </AccordionDetails>
    </Accordion>
  );
}
AssetAccordion.propTypes = {
  asset: PropTypes.instanceOf(Object),
  id: PropTypes.string,
  expandedProp: PropTypes.bool,
};
AssetAccordion.defaultProps = {
  asset: undefined,
  id: undefined,
  expandedProp: false,
};
