import routes from '../../config/routes';

const isThisLocation = (localPath, pathname) => pathname.substring(0, localPath.length) === localPath;

const itemIsSelected = (_path, pathname) => {
  // If no specific location is matched, default to 'Assets'
  if (routes.find((route) => route.path === pathname) === undefined) {
    return _path === '/assets'; // Default to assets if no other path is matched
  }
  return isThisLocation(_path, pathname);
};

export default itemIsSelected;
