/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
import {
  makeStyles, Typography, Checkbox, Table, TableBody, TableContainer, TableRow, Paper, TableCell, TableHead, withStyles, InputLabel,
  FormControlLabel, Switch, Box, LinearProgress, Grid, IconButton, Divider, Card, CardHeader, CardContent,
  Button, Stepper, Step, StepLabel, FormControl, Select, MenuItem, ListItemText,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@tanstack/react-query';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { motion } from 'framer-motion';
import { useHistory, useRouteMatch } from 'react-router-dom';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useProject } from '../../config/ProjectContext';
import GraphqlService from '../../service/graphqlService';
import PaginationComponent from '../../components/Pagination';
import ProgressDialog from '../../components/utils/ProgressDialog';
import MessageCard from '../../components/utils/MessageCard';
import { useSnackbar } from '../../providers/SnackbarContext';
import SelectedAssetsContext from '../../config/SelectedAssetsContext';

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: theme.spacing(2),
  },
  headerCheckbox: {
    color: theme.palette.common.black,
    zIndex: 1000,

  },
  container: {
    padding: theme.spacing(3),
  },
  stepContent: {
    padding: theme.spacing(2),
  },
  tableIcons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingBottom: 16,
    flexWrap: 'wrap',
    marginTop: 8,
  },
  assetDetailFooter: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    backgroundColor: 'white',
    boxShadow: theme.shadows[3],
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    zIndex: 1300,
    [theme.breakpoints.up('md')]: {
      marginLeft: 279,
    },
    height: '55%',
    // if it's mobile, the height is 100% of the screen
    [theme.breakpoints.down('sm')]: {
      height: '80%',
    },
    // add and scroll to the asset detail footer
    overflow: 'auto',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginRight: 280,
    },
  },
  titleDivide: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  assetDetailTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardTitle: {
    fontSize: 16,
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  },
  cardHeader: {
    padding: 4,
  },
  cardTopHeader: {
    padding: 0,
    paddingLeft: 2,
    marginBottom: -28,
  },
  cardContent: {
    backgroundColor: 'white',
    padding: 8,
    height: '100%',
    boxShadow: 'none',
    border: 'none',
  },
  detailIcon: {
    // size of the icon has to be smaller than the row height
    padding: 0,
  },
  selectedItems: {
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
  button: {
    marginLeft: theme.spacing(1),
  },

  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  wizardContainer: {
    padding: theme.spacing(3),
    position: 'relative',
  },
  stepWizardContent: {
    padding: theme.spacing(2),
  },
  stepLabel: {
    cursor: 'pointer',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  highlightArrow: {
    position: 'absolute',
    zIndex: 2000,
    color: theme.palette.primary.main,
  },
  highlightText: {
    position: 'absolute',
    width: 600,
    backgroundColor: 'transparent', // Set background to transparent
    color: 'orange',
    // bold
    fontWeight: 'bold',
  },
  infoFormfUncomplete: {
    color: 'red',
    fontSize: 14,

  },
  filters: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    alignItems: 'flex-end',
  },
  assetTypeFilterStyle: {
    minWidth: 210,
  },
  footerButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: 'rgba(0, 0, 0, 0.02)',
    },
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    // selected
    '&$selected': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
}))(TableRow);

export default function BundleWizard({ onComplete, onCancel, assetTypes }) {
  const classes = useStyles();

  const [description, setDescription] = useState('');
  const [title, setTitle] = useState('');
  const [deployAfterCreate, setDeployAfterCreate] = useState(false);
  const [selectedAssetDetail, setSelectedAssetDetail] = useState(null);
  const { project } = useProject();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [allChecked, setAllChecked] = useState(false);
  const [indeterminateCheck, setIndeterminateCheck] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const [openAssetTypeFilter, setOpenAssetTypeFilter] = useState(false);

  const [closeAssetTypeFilter, setCloseAssetTypeFilter] = useState(false);
  const [selectedAssetTypes, setSelectedAssetTypes] = useState([]);
  const [assetTypeFilter, setAssetTypeFilter] = useState();

  const { selectedAssets, setSelectedAssets } = useContext(SelectedAssetsContext);

  const openSnackBar = useSnackbar();

  const handleCloseAssetTypeFilter = () => {
    setOpenAssetTypeFilter(false);
    setCloseAssetTypeFilter(assetTypeFilter);
  };

  const handleOpenAssetTypeFilter = () => {
    setOpenAssetTypeFilter(true);
  };

  const handleStepClick = (step) => {
    setActiveStep(step);
  };

  const handleCancel = () => {
    onCancel();
  };

  useEffect(() => {
    setSelectedAssets([]);
    setAllChecked(false);
    setIndeterminateCheck(false);
    setActiveStep(0);
    setDescription('');
    setTitle('');
    setDeployAfterCreate(false);
  }, [project]);

  const getType = closeAssetTypeFilter === null ? null : assetTypeFilter;

  const handleAssetTypeFilterChange = (event) => {
    const { value } = event.target;
    // Need to handle the selection as an array
    setSelectedAssetTypes(value);
    // transform the array in a string with values sepparated by commas
    if (!value) {
      setAssetTypeFilter(null);
    } else setAssetTypeFilter(value.join(',').toString());
  };

  const fetchAssets = async () => GraphqlService.listAssetsNotDeleted({
    projectId: project?.code,
    page: currentPage,
    pageSize,
    sortDirection: 'DESC',
    type: getType,
  });

  const isSelected = (asset) => selectedAssets.find((b) => b.fileID === asset.fileID) !== undefined;

  const {
    data: assets,
    error: errorFetchingAssets,
    isLoading: isLoadingAssets,
  } = useQuery(['assets', project?.code, project?.permission, currentPage, pageSize, getType], fetchAssets, {
    enabled: !!project?.code,
    cacheTime: 0,
    select: (assetsResponse) => {
      const transformedAssets = assetsResponse?.data?.map((asset) => {
        try {
          const parsedDescription = asset?.assetDescription ? JSON.parse(asset.assetDescription) : {};
          return {
            ...asset,
            assetDescription: parsedDescription,
          };
        } catch (error) {
          return asset;
        }
      });
      return { ...assetsResponse, data: transformedAssets };
    },
  });

  const handleSelectAllClick = () => {
    if (assets?.data?.length === selectedAssets?.length) {
      setSelectedAssets([]);
      setAllChecked(false);
    } else {
      const newSelecteds = assets?.data?.map((n) => n);
      console.log('newSelecteds assets', newSelecteds);
      setSelectedAssets(newSelecteds);
      setAllChecked(true);
    }
    setIndeterminateCheck(false);
  };

  const handleClick = (_event, asset) => {
    const selectedIndex = selectedAssets.indexOf(asset);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedAssets, asset);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedAssets.slice(1));
    } else if (selectedIndex === selectedAssets?.length - 1) {
      newSelected = newSelected.concat(selectedAssets.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedAssets.slice(0, selectedIndex),
        selectedAssets.slice(selectedIndex + 1),
      );
    }

    if (newSelected.length === assets?.data?.length) {
      setAllChecked(true);
      setIndeterminateCheck(false);
    } else if (newSelected.length === 0) {
      setAllChecked(false);
      setIndeterminateCheck(false);
    } else {
      setIndeterminateCheck(true);
      setAllChecked(false);
    }

    setSelectedAssets(newSelected);
  };

  const handleDetailClick = (asset) => {
    setSelectedAssetDetail(asset);
  };

  const handlePageChange = (_event, value) => {
    setCurrentPage(value - 1);
  };

  const handleSelectChange = (event) => {
    setPageSize(event.target.value);
  };

  const createBundleMutation = useMutation((variables) => GraphqlService.createBundle(variables.projectId, variables.release));

  const history = useHistory();
  const match = useRouteMatch();

  const handleComplete = async () => {
    try {
      await createBundleMutation.mutateAsync({
        projectId: project?.code,
        release: {
          releaseDescription: {
            description,
          },
          title,
          fileIDs: selectedAssets.map((asset) => asset.fileID),
        },
      });

      if (deployAfterCreate) {
        // Redirect to the deployed asset page
        history.push(`${match.url.replace('/bundles', 'assets/deployments/create')}`);
      }
      openSnackBar('Bundle created successfully', 'success');
      onComplete();
    } catch (error) {
      openSnackBar('Error creating bundles', 'error');
      console.error('Error creating bundle:', error);
    }
  };

  const steps = [
    {
      label: 'Select Assets',
      description: 'Select the assets you want to add in this Bundle',
      content: (
        <Box>
          <div>
            <Typography className={classes.selectedItems}>
              {`${selectedAssets?.length || 0} assets selected`}
            </Typography>
          </div>
          <Grid container className={classes.filters}>
            {
              assetTypes?.length > 0 && (
                <Grid item>
                  <FormControl variant="filled" className={classes.assetTypeFilterStyle}>
                    <InputLabel>Asset Type Filter</InputLabel>
                    <Select
                      multiple
                      open={openAssetTypeFilter}
                      onOpen={handleOpenAssetTypeFilter}
                      onClose={handleCloseAssetTypeFilter}
                      value={selectedAssetTypes}
                      onChange={handleAssetTypeFilterChange}
                      className={classes.selectPaginationSize}
                      renderValue={(selected) => selected.join(', ')}
                    >
                      {assetTypes?.map((assetType) => (
                        <MenuItem key={assetType} value={assetType}>
                          <Checkbox checked={selectedAssetTypes.indexOf(assetType) > -1} />
                          <ListItemText primary={assetType} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )
            }
          </Grid>
          <TableContainer component={Paper} className={classes.table}>
            <Table aria-label="select assets table">
              <TableHead>
                <TableRow>
                  <StyledTableCell padding="checkbox">
                    <Checkbox
                      indeterminate={indeterminateCheck}
                      checked={allChecked}
                      onChange={handleSelectAllClick}
                      className={classes.headerCheckbox}
                    />
                  </StyledTableCell>
                  <StyledTableCell>Type</StyledTableCell>
                  <StyledTableCell>Version</StyledTableCell>
                  <StyledTableCell>Creation Date</StyledTableCell>
                  <StyledTableCell>In Bundle</StyledTableCell>
                  <StyledTableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {assets?.data?.map((asset) => {
                  const itemSelected = isSelected(asset);
                  return (
                    <StyledTableRow
                      key={`${asset.fileID}-0`}
                      onClick={(event) => handleClick(event, asset)}
                      role="checkbox"
                      aria-checked={itemSelected}
                      selected={itemSelected}
                    >
                      <StyledTableCell padding="checkbox">
                        <Checkbox checked={itemSelected} />
                      </StyledTableCell>
                      <StyledTableCell id={`${asset.filedId}-1`}>{asset.type}</StyledTableCell>
                      <StyledTableCell id={`${asset.filedId}-2`}>{asset.assetDescription?.Version}</StyledTableCell>
                      <StyledTableCell id={`${asset.filedId}-3`}>{new Date(asset.dateTime).toLocaleString()}</StyledTableCell>
                      <StyledTableCell id={`${asset.filedId}-4`}>
                        {
                          asset.releaseIDs?.length > 0 ? 'Yes' : 'No'
                        }
                      </StyledTableCell>
                      <StyledTableCell id={`${asset.filedId}-5`}>
                        <IconButton onClick={() => handleDetailClick(asset)} className={classes.detailIcon}>
                          <VisibilityIcon />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <div className={classes.tableIcons}>
            {assets?.pagination && (
              <PaginationComponent
                pageSize={pageSize}
                handleSelectChange={handleSelectChange}
                pagination={assets.pagination}
                handlePageChange={handlePageChange}
              />
            )}
          </div>
          {selectedAssetDetail && (
            <motion.div
              key="selectedAssetDetail"
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.1 }}
            >
              <Box mt={2} p={2} border={1} borderRadius={4} className={classes.assetDetailFooter}>
                <Box className={classes.assetDetailTitleContainer}>
                  <IconButton className={classes.closeButton} onClick={() => setSelectedAssetDetail(null)}>
                    <CloseIcon />
                  </IconButton>
                  <Typography variant="h4">
                    {`${selectedAssetDetail.type} - ${selectedAssetDetail.assetDescription?.Version} - ${new Date(selectedAssetDetail.dateTime).toLocaleString()}`}
                  </Typography>
                </Box>
                <Divider className={classes.titleDivide} />
                <Grid container alignItems="stretch">
                  <Grid item xs={6} md={6}>
                    <Card className={classes.cardContent}>
                      <CardHeader
                        className={classes.cardHeader}
                        title={<div className={classes.cardTitle}>File ID</div>}
                        subheader={<div className={classes.cardTitle} id={`${selectedAssetDetail.fileID}File`}>{selectedAssetDetail.fileID}</div>}
                      />
                    </Card>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Card className={classes.cardContent}>
                      <CardHeader
                        className={classes.cardHeader}
                        title={<div className={classes.cardTitle}>Chunks</div>}
                        subheader={<div className={classes.cardTitle} id={`${selectedAssetDetail.fileID}Chunks`}>{Array.isArray(selectedAssetDetail.chunks) ? selectedAssetDetail.chunks.map((path) => path.split('/').pop()).join(', ') : 'No info for chunks'}</div>}
                      />
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Card className={classes.cardContent}>
                      <CardHeader
                        className={classes.cardHeader}
                        title={<div className={classes.cardTitle}>Manufacturer signature</div>}
                        subheader={<div className={classes.cardTitle} id={`${selectedAssetDetail.fileID}Ms`}>{selectedAssetDetail.manufacturerSignature || 'There is no value for the manufacturer signature'}</div>}
                      />
                    </Card>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Card className={classes.cardContent}>
                      <CardHeader
                        className={classes.cardHeader}
                        title={<div className={classes.cardTitle}>Software Requirements</div>}
                        subheader={<div className={classes.cardTitle} id={`${selectedAssetDetail.fileID}Sw`}>{selectedAssetDetail.softwareRequirements || 'No info for software requirements'}</div>}
                      />
                    </Card>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Card className={classes.cardContent}>
                      <CardHeader
                        className={classes.cardHeader}
                        title={<div className={classes.cardTitle}>Hardware Requirements</div>}
                        subheader={<div className={classes.cardTitle} id={`${selectedAssetDetail.fileID}Hw`}>{selectedAssetDetail.hardwareRequirements || 'No info for hardware requirements'}</div>}
                      />
                    </Card>
                  </Grid>
                  {selectedAssetDetail.typeRequirements && selectedAssetDetail.typeRequirements.length > 0 && (
                    <Grid item xs={6} md={6}>
                      <Card className={classes.cardContent}>
                        <CardHeader
                          className={classes.cardHeader}
                          title={<div className={classes.cardTitle}>Type Requirements</div>}
                          subheader="Type requirements for the asset."
                        />
                        <CardContent>
                          <Grid container alignItems="stretch">
                            <Grid item xs={6}>
                              <Card className={classes.cardContent}>
                                <CardHeader
                                  className={classes.cardHeader}
                                  title={<div className={classes.cardTitle}>Type</div>}
                                  subheader={<div className={classes.cardTitle} id={`${selectedAssetDetail.fileID}Tr`}>{selectedAssetDetail.typeRequirements[0].type || 'No info for type'}</div>}
                                />
                              </Card>
                            </Grid>
                            <Grid item xs={6} md={6}>
                              <Card className={classes.cardContent}>
                                <CardHeader
                                  className={classes.cardHeader}
                                  title={<div className={classes.cardTitle}>Software requirements</div>}
                                  subheader={<div className={classes.cardTitle} id={`${selectedAssetDetail.fileID}TrSw`}>{selectedAssetDetail.typeRequirements[0].softwareRequirements || 'No info for software requirements'}</div>}
                                />
                              </Card>
                            </Grid>
                            <Grid item xs={6} md={6}>
                              <Card className={classes.cardContent}>
                                <CardHeader
                                  className={classes.cardHeader}
                                  title={<div className={classes.cardTitle}>Hardware requirements</div>}
                                  subheader={<div className={classes.cardTitle} id={`${selectedAssetDetail.fileID}TrHw`}>{selectedAssetDetail.typeRequirements[0].hardwareRequirements || 'No info for hardware requirements'}</div>}
                                />
                              </Card>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  )}
                  {selectedAssetDetail.assetDescription && (
                    Object.entries(selectedAssetDetail.assetDescription).map(([key, value]) => (
                      <Grid item xs={6} md={6} key={key}>
                        <Card className={classes.cardContent}>
                          <CardHeader
                            className={classes.cardHeader}
                            title={<div className={classes.cardTitle}>{key}</div>}
                            subheader={value || '-'}
                          />
                        </Card>
                      </Grid>
                    ))
                  )}
                  {(selectedAssetDetail.metaData.major || selectedAssetDetail.metaData.minor || selectedAssetDetail.metaData.build || selectedAssetDetail.metaData.hash) && (
                    <Grid container alignItems="stretch">
                      <Grid item xs={6} md={6}>
                        <Card className={classes.cardContent}>
                          <CardHeader
                            className={classes.cardHeader}
                            title={<div className={classes.cardTitle}>Major</div>}
                            subheader={<div className={classes.cardTitle} id={`${selectedAssetDetail.fileID}Major`}>{selectedAssetDetail.metaData.major || 'No info for major version'}</div>}
                          />
                        </Card>
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <Card className={classes.cardContent}>
                          <CardHeader
                            className={classes.cardHeader}
                            title={<div className={classes.cardTitle}>Minor</div>}
                            subheader={<div className={classes.cardTitle} id={`${selectedAssetDetail.fileID}Minor`}>{selectedAssetDetail.metaData.minor || 'No info for minor version'}</div>}
                          />
                        </Card>
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <Card className={classes.cardContent}>
                          <CardHeader
                            className={classes.cardHeader}
                            title={<div className={classes.cardTitle}>Build</div>}
                            subheader={<div className={classes.cardTitle} id={`${selectedAssetDetail.fileID}Build`}>{selectedAssetDetail.metaData.build || 'No info for build'}</div>}
                          />
                        </Card>
                      </Grid>
                      <Grid item xs={6}>
                        <Card className={classes.cardContent}>
                          <CardHeader
                            className={classes.cardHeader}
                            title={<div className={classes.cardTitle}>Hash</div>}
                            subheader={<div className={classes.cardTitle} id={`${selectedAssetDetail.fileID}Hash`}>{selectedAssetDetail.metaData.hash || 'No info for hash'}</div>}
                          />
                        </Card>
                      </Grid>
                      <Grid item xs={6}>
                        <Card className={classes.cardContent}>
                          <CardHeader
                            className={classes.cardHeader}
                            title={<div className={classes.cardTitle}>Raw</div>}
                            subheader={<div className={classes.cardTitle} id={`${selectedAssetDetail.fileID}Raw`}>{selectedAssetDetail.metaData.raw || 'No info for raw'}</div>}
                          />
                        </Card>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </motion.div>
          )}
        </Box>
      ),
    },
    {
      label: 'Set title and description',
      description: 'Add title and description to your Bundle',
      content: (
        <Grid container>
          <Grid item xs={12}>
            <InputLabel htmlFor="title">Bundle Title</InputLabel>
            <textarea
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              rows="1"
              cols="50"
              style={{ width: '100%', padding: '8px', marginTop: '8px' }}
            />
          </Grid>

          <Grid item xs={12}>
            <InputLabel htmlFor="description">Bundle Description</InputLabel>
            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              rows="4"
              cols="50"
              style={{ width: '100%', padding: '8px', marginTop: '8px' }}
            />
          </Grid>
        </Grid>
      ),
    },
    {
      label: 'Confirm Bundle',
      description: 'Review and create your Bundle',
      content: (
        <Grid container display="flex" spacing={4}>
          <Grid item xs={12}>
            <TableContainer component={Paper} className={classes.table}>
              <Table aria-label="selected assets table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Type</StyledTableCell>
                    <StyledTableCell>Version</StyledTableCell>
                    <StyledTableCell>Creation Date</StyledTableCell>
                    <StyledTableCell>In Bundle</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedAssets.map((asset) => (
                    <StyledTableRow key={asset.fileID}>
                      <StyledTableCell>{asset.type}</StyledTableCell>
                      <StyledTableCell>{asset.assetDescription?.Version}</StyledTableCell>
                      <StyledTableCell>{new Date(asset.dateTime).toLocaleString()}</StyledTableCell>
                      <StyledTableCell>{asset.inBundle}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12}>
            <InputLabel htmlFor="title"> Title</InputLabel>
            <textarea
              id="title"
              disabled
              value={title}
              rows="4"
              cols="50"
              style={{ width: '100%', padding: '8px', marginTop: '8px' }}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel htmlFor="description"> Description</InputLabel>
            <textarea
              id="description"
              disabled
              value={description}
              rows="4"
              cols="50"
              style={{ width: '100%', padding: '8px', marginTop: '8px' }}
            />
          </Grid>
          <Grid item xs={12}>
            {selectedAssets.length === 0 && (
              <Typography className={classes.infoFormfUncomplete}>
                * No assets has been selected, please select at least one asset to create a bundle
              </Typography>
            )}
            {!title && (
              <Typography className={classes.infoFormfUncomplete}>
                * No title has been added, please add a title to create a bundle
              </Typography>
            )}
            {!description && (
              <Typography className={classes.infoFormfUncomplete}>
                * No description has been added, please add a description to create a bundle
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Switch checked={deployAfterCreate} onChange={(e) => setDeployAfterCreate(e.target.checked)} />}
              label="Deploy after creation"
              disabled={selectedAssets.length === 0 || !description || !title}
            />
          </Grid>

        </Grid>
      ),
    },
  ];

  if (isLoadingAssets || !project?.code) {
    return (
      <>
        <ProgressDialog open header="Retrieving assets, please wait" />
        <LinearProgress id="linear-progress-id" style={{ width: '100%' }} />
      </>
    );
  }

  if (errorFetchingAssets) {
    return <MessageCard message={`Error: ${errorFetchingAssets?.message}`} />;
  }

  const handlePrevious = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  return (
    <Box className={classes.wizardContainer}>
      <Stepper activeStep={activeStep}>
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              className={classes.stepLabel}
              onClick={() => handleStepClick(index)}
            >
              {step.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <motion.div
        key={activeStep}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -0.5 }}
        transition={{ duration: 0.5 }}
      >
        <div className={classes.stepWizardContent}>
          <Typography className={classes.instructions}>
            {steps[activeStep].description}
          </Typography>
          {steps[activeStep].content}
        </div>
      </motion.div>
      <Box className={classes.footerButtons}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCancel}
          className={classes.button}
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={handlePrevious}
          startIcon={<ArrowBackIcon />}
          disabled={activeStep === 0}
          className={classes.button}
        >
          Previous
        </Button>
        {activeStep === steps.length - 1 && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleComplete}
            disabled={selectedAssets.length === 0 || !description || !title}
            className={classes.button}
          >
            {deployAfterCreate ? 'Create and Deploy' : 'Create'}
          </Button>
        )}
        {activeStep !== steps.length - 1 && (
          <Button
            variant="contained"
            color="primary"
            startIcon={<ArrowForwardIcon />}
            onClick={handleNext}
            className={classes.button}
          >
            Next
          </Button>
        )}
      </Box>

    </Box>
  );
}

BundleWizard.propTypes = {
  onComplete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  assetTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
};
