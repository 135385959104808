/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
import {
  Box, Grid, makeStyles, Table,
  TableBody, TableContainer, TableFooter, TablePagination, TableRow,
  LinearProgress, Paper, TableCell, TableHead, Card, CardContent, Typography,
  withStyles, Button,

} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  React,
  useEffect,
  useState,
} from 'react';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CodeIcon from '@material-ui/icons/Code';
import TablePaginationActions from '../../../components/utils/TablePaginationActions';
import SecondaryButton from '../../../components/utils/SecondaryButton';
import {
  getColumnInclude, transformComplexJson, extractKeys, deleteParentfromHeader, completeNullDataAccordingToHeader,
} from '../../../utils/objectTable';
import useCSVDeviceRawDataExport from '../../../hooks/useCSVDeviceRawDataExport';
import { useSnackbar } from '../../../providers/SnackbarContext';

const useStyles = makeStyles((theme) => ({
  headerTable: {
    font: theme.typography.h5.font,
    fontSize: 12,
    color: theme.palette.common.black,
  },
  headerBorderTable: {
    borderBottom: `1.5px solid ${theme.palette.primary.banner}`,
  },
  tableButton: {
    height: 38,
    justifyContent: 'center',
    margin: 4,
    color: 'white',
    borderRadius: 12,
  },
  expandGridButton: {
    marginTop: -3,
  },
  bottomRightExportButton: {
    paddingRight: 8,
    paddingLeft: 8,
    paddingBottom: 16,
    textAlign: 'right',
    alignSelf: 'flex-end',
    borderRadius: 0,
    width: 138,
  },
  content: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 24,
    paddingRight: 24,
    backgroundColor: theme.palette.background.typography,
  },
  exportCSVText: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightRegular,
    textTransform: 'none',
  },
  contentGrid: {
    paddingBottom: 16,
  },
  cardNoMachineTelemetryText: {
    color: theme.palette.primary.text,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 16,
    textAlign: 'center',
  },
  detailsTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium,
    paddingRight: '5px',
  },
  headingTitle: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
  },
  machineInfoCardPadding16: {
    padding: '16px !important',
  },
  telemetryTable: {
    borderRadius: 12,
    boxShadow: theme.customShadows.noTopShadow,
  },
  bottomLeftLegend: {
    paddingTop: 4,
  },
  cardNoMachineTelemetry: {
    borderRadius: 12,
  },

  cellWithMaxWidth: {
    maxWidth: '400px', // Adjust the value as needed
    wordWrap: 'break-word', // Ensure long words break to fit
  },
  cellNoWrapWithEllipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  mediumCellWith: {
    maxWidth: '80px', // Adjust the value as needed
    wordWrap: 'break-word', // Ensure long words break to fit
  },
  timestampCell: {
    maxWidth: '30px', // Set a specific width for timestamp
    wordWrap: 'break-word', // Ensure long words break to fit
    whiteSpace: 'normal', // Allow text to wrap
  },
  deviceSerialCell: {
    maxWidth: '400px', // Set a specific width for timestamp
    wordWrap: 'break-word', // Ensure long words break to fit
    whiteSpace: 'normal', // Allow text to wrap
  },
}));

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.form,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    minWidth: 100,
    maxWidth: '400px', // Adjust the value as needed
    wordWrap: 'break-word', // Ensure long words break to fit
  },
}))(TableCell);

export default function DeviceRawDataTable({
  search, dataList, totalCount, filters, loading, dateIni, dateEnd, rangeDates,
  page, setPage, rowsPerPage, object, params, id, pagination, paginationTokens, columnInclude,
}) {
  const [rawData, setRawData] = useState([]);

  const [expandJson, setExpandJson] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    search(newPage);
  };

  // WEBPAGE______________________________
  const classes = useStyles();

  useEffect(() => { }, []);

  const openSnackBar = useSnackbar();

  useEffect(() => {
    setRawData(getColumnInclude(dataList, columnInclude));
  }, [dataList]);

  let transformedData = transformComplexJson(rawData);

  const dataHeaderExport = extractKeys(transformedData);

  // delete from the header list all the parents that have some info in some rows and are null in the others
  const headerExport = deleteParentfromHeader(dataHeaderExport);
  // alphabetically
  transformedData = transformedData.map(completeNullDataAccordingToHeader(headerExport));

  const {
    exportToCSV, loading: exportLoading,
  } = useCSVDeviceRawDataExport(
    params, filters, dateIni, dateEnd, rangeDates, object,
  );

  const copyPrettyJsonToClipboard = (jsonObject) => {
    try {
      // Check if jsonObject is already a string or an object
      // If it's a string, parse it to JSON first (it should be valid JSON string)
      const data = typeof jsonObject === 'string' ? JSON.parse(jsonObject) : jsonObject;

      // Convert JSON object to a formatted string
      const formattedJson = JSON.stringify(data, null, 2);

      // Use the Clipboard API to copy the text
      navigator.clipboard.writeText(formattedJson)
        .then(() => {
          openSnackBar('Message Body copied to clipboard successfully!', 'success');
        })
        .catch((err) => {
          openSnackBar(`Failed to copy JSON to clipboard: ${err}`, 'error');
        });
    } catch (err) {
      openSnackBar(`Error handling the JSON data: ${err}`, 'error');
    }
  };

  return (
    <Box>
      <Grid
        container
        spacing={0}
        direction="row"
        className={classes.content}
        id="brand-events-table"
      >
        {(rawData !== null) && (Array.isArray(rawData)) && (rawData.length > 0)
          && (
            <Grid
              container
              spacing={0}
              direction="row"
              className={classes.contentGrid}
            >
              <Grid item xs={6} className={classes.bottomLeftLegend}>
                <Typography className={classes.bottomLeftLegend}>Data will be retrieved in real time</Typography>
              </Grid>

              <Grid item xs={6} className={classes.bottomRightExportButton}>
                <Grid container direction="row" spacing={2} justifyContent="flex-end">
                  <Grid item className={classes.expandGridButton}>

                    <Button id="btnExpand" type="form" startIcon={<CodeIcon />} className={classes.tableButton} color="secondary" variant="contained" onClick={() => setExpandJson(!expandJson)}>
                      {expandJson ? 'Collapse JSON' : 'Expand JSON'}
                    </Button>

                  </Grid>
                  <Grid item>

                    <span>
                      <SecondaryButton
                        id={`btn${id}Export`}
                        onClick={exportToCSV}
                        disabled={exportLoading}
                      >
                        Export .CSV
                      </SecondaryButton>
                    </span>

                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                className={classes.separate}
              >
                <TableContainer component={Paper} className={classes.telemetryTable}>
                  <Table className={classes.historyTable} aria-label="custom pagination table">
                    <TableHead>
                      <TableRow>

                        <StyledTableCell className={classes.timestampCell}>
                          Timestamp
                        </StyledTableCell>
                        <StyledTableCell className={classes.deviceSerialCell}>
                          Device Serial
                        </StyledTableCell>
                        <StyledTableCell className={classes.cellWithMaxWidth}>
                          Direction
                        </StyledTableCell>
                        <StyledTableCell className={classes.cellWithMaxWidth}>
                          Market
                        </StyledTableCell>

                        <StyledTableCell className={classes.cellWithMaxWidth}>
                          Message Type
                        </StyledTableCell>
                        <StyledTableCell className={classes.cellWithMaxWidth}>
                          Protocol Version
                        </StyledTableCell>
                        <StyledTableCell className={classes.cellWithMaxWidth}>
                          Reject Reason
                        </StyledTableCell>
                        <StyledTableCell className={
                          expandJson
                            ? classes.cellWithMaxWidth
                            : classes.cellNoWrapWithEllipsis
                        }
                        >
                          Message Body
                        </StyledTableCell>
                        <StyledTableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {transformedData.map((item, i) => (
                        <StyledTableRow key={i}>

                          <StyledTableCell className={classes.timestampCell}>
                            {item.timestamp}
                          </StyledTableCell>

                          <StyledTableCell className={classes.deviceSerialCell}>
                            {item.deviceSerial}
                          </StyledTableCell>

                          <StyledTableCell className={classes.mediumCellWith}>
                            {item.direction}
                          </StyledTableCell>

                          <StyledTableCell className={classes.mediumCellWith}>
                            {item.market}
                          </StyledTableCell>

                          <StyledTableCell className={classes.mediumCellWith}>
                            {item.messageType}
                          </StyledTableCell>

                          <StyledTableCell className={classes.mediumCellWith}>
                            {item.protocolVersion}
                          </StyledTableCell>

                          <StyledTableCell className={classes.mediumCellWith}>
                            {item.rejectReason}
                          </StyledTableCell>

                          <StyledTableCell className={
                            expandJson ? classes.cellWithMaxWidth
                              : classes.cellNoWrapWithEllipsis
                          }
                          >
                            {item.messageBody}
                          </StyledTableCell>
                          <StyledTableCell className={classes.timestampCell}>
                            <Button id="btnSearch" type="form" startIcon={<FileCopyIcon />} className={classes.tableButton} color="secondary" variant="contained" onClick={() => copyPrettyJsonToClipboard(item.messageBody)}> JSON </Button>

                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>

                    <TableFooter>
                      <TableRow key="paginationFooter">
                        {pagination === 'limit&offset'
                          ? (
                            <TablePagination
                              rowsPerPageOptions={[20]}
                              colSpan={10}
                              count={totalCount}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                native: true,
                              }}
                              onChangePage={handleChangePage}
                              ActionsComponent={TablePaginationActions}
                            />
                          ) : (
                            <TablePagination
                              rowsPerPageOptions={[rowsPerPage]}
                              colSpan={10}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                native: true,
                              }}
                              onChangePage={handleChangePage}
                              ActionsComponent={TablePaginationActions}
                              labelDisplayedRows={({ from, to, count }) => (((count !== -1 && paginationTokens.length > 1) || rawData.length < 100)
                                ? `${from}-${to + (page * 100)} of ${count + ((paginationTokens?.length - 1) * 100)}`
                                : `${from}-${to} of more than ${to}`)}
                              count={
                                paginationTokens?.length > 1 && rawData?.length === rowsPerPage
                                  ? -1
                                  : rawData?.length
                              }
                            />
                          )}
                      </TableRow>
                    </TableFooter>

                  </Table>
                  {loading && <LinearProgress />}
                </TableContainer>
              </Grid>
            </Grid>

          )}
        {(rawData !== null) && (Array.isArray(rawData)) && (rawData.length === 0)
          && !loading
          && (
            <Grid
              item
              xs={12}
              className={classes.separateVoidGrid}
            >
              <Card className={classes.cardNoMachineTelemetry}>
                <CardContent className={classes.machineInfoCardPadding16}>
                  <Typography className={classes.cardNoMachineTelemetryText}>
                    {`No ${id} available`}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          )}
      </Grid>
    </Box>
  );
}
DeviceRawDataTable.propTypes = {
  search: PropTypes.func.isRequired,
  dataList: PropTypes.array.isRequired,
  totalCount: PropTypes.number.isRequired,
  filters: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  dateIni: PropTypes.object.isRequired,
  dateEnd: PropTypes.object.isRequired,
  rangeDates: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  object: PropTypes.string.isRequired,
  params: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  pagination: PropTypes.string,
  paginationTokens: PropTypes.array,
  columnInclude: PropTypes.string || PropTypes.array,
};
DeviceRawDataTable.defaultProps = {
  pagination: 'limit&offset',
  paginationTokens: [],
  columnInclude: '',
};
