/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
import {
  Grid, InputLabel, makeStyles, LinearProgress,
  TableCell, Table, TableBody, TableContainer, Paper, withStyles, TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  React, useEffect, useState,
} from 'react';
import GraphqlService from '../../../service/graphqlService';
import { useProject } from '../../../config/ProjectContext';

const useStyles = makeStyles((theme) => ({
  labelSummary: {
    color: theme.palette.primary.subMain,
    paddingBottom: '8px',
    paddingTop: '4px',
  },
  summaryContent: {
    minHeight: '116px',
    borderBottom: `1px solid ${theme.palette.primary.subMain}`,
    padding: '16px',
  },
  leftSummary: {
    paddingLeft: '24px',
    flexBasis: '12.666667%',
  },
  rightSummaryTitle: {
    backgroundColor: theme.palette.background.form,
    flexBasis: '7.666667%',
  },
  rightSummary: {
    backgroundColor: theme.palette.background.form,
  },
  firmware: {
    paddingBottom: '4px',
  },
  telemetryTable: {
    marginTop: 4,
    borderRadius: 12,
    boxShadow: theme.customShadows.noTopShadow,
  },
}));

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#E0E0E0',
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    minWidth: 100,
    maxWidth: '400px', // Adjust the value as needed
    wordWrap: 'break-word', // Ensure long words break to fit
  },
}))(TableCell);

export default function NewDeploymentHeader({
  projectCode, assets,
}) {
  const [loading, setLoading] = useState(false);
  const { project } = useProject();
  const classes = useStyles();

  // WEBPAGE______________________________

  return (
    <Grid
      container
      spacing={0}
      alignItems="top"
      className={classes.summaryContent}
    >
      <Grid item xs={12} className={classes.loadingSummary}>
        {loading && <LinearProgress />}
      </Grid>

      <InputLabel>Selected Assets</InputLabel>

      <TableContainer component={Paper} className={classes.telemetryTable}>
        <Table>
          <TableBody>
            {(assets && assets.length > 0) ? assets.map((asset) => (
              <StyledTableRow>

                <StyledTableCell>
                  ✅
                  {' '}
                  {`${asset.type ? asset.type : ''} v.${asset.assetDescription ? asset.assetDescription?.Version : '-'}`}
                </StyledTableCell>
                <StyledTableCell>
                  {`${asset.fileID}`}
                </StyledTableCell>
                <StyledTableCell>
                  {`${asset.assetDescription?.Description || '-'}`}
                </StyledTableCell>

              </StyledTableRow>

            )) : (
              <>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>

    </Grid>
  );
}
NewDeploymentHeader.propTypes = {
  projectCode: PropTypes.number.isRequired,
  assets: PropTypes.array.isRequired,
};
